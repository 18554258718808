.content-pill-option {
  font-family: 'Proxima Nova';
  width: 49%;
  height: 13px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 350px) {
    font-size: 11px;
  }

  &--free {
    color: $andes-green-500;
    font-weight: 500;
  }

  &-icon {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-right: 5px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .pill-option-icon-international-logo {
    width: 200px !important;
    height: 50px !important;
    transform: translateY(-8px);
  }

  .ui-search-icon--shippingtruck {
    fill: $andes-green-500;
  }
}
