.ui-search-collapsible-card {
  margin-bottom: $andes-spacing-8;

  &__header {
    border-bottom: none;
  }

  &__content {
    background-color: $andes-white;
    border-top: 1px solid $andes-gray-070;
    display: none;

    &--is-expanded {
      display: block;
    }
  }

  &__content-text {
    font-size: $font-size-14;
    line-height: 22px;
    padding: 16px 12px;
    color: $andes-text-color-secondary;
  }

  &__container {
    text-align: left;
    align-items: center;
    background-color: inherit;
    border: none;
    color: $andes-text-color-primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 12px;
    width: 100%;
  }

  &__title {
    font-size: $font-size-14;
  }

  &__icon {
    display: flex;
    transform: rotate(0deg);
    transition: transform $quick-transition-duration;
    margin-left: $andes-spacing-12;

    &--is-expanded {
      transform: rotate(180deg);
      transition: transform $quick-transition-duration;
    }
  }
}
