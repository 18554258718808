@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-card-attributes {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.#{$search-prefix}-card-attributes__attribute {
  color: $search-gray-1000;
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  margin-right: $andes-spacing-4;

  &:not(:last-child)::after {
    margin-left: $andes-spacing-4;
    content: '|';
  }
}
