@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/mixins.desktop-small';

.#{$search-prefix}-item__group--title-grid {
  margin-bottom: $andes-spacing-12 !important;

  .#{$search-prefix}-item__subtitle-grid {
    font-size: $font-size-12;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-link__title-card::before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.#{$search-prefix}-item__title-grid {
  .#{$search-prefix}-item__title {
    font-size: $font-size-14 !important;
    font-weight: $font-weight-normal !important;
    color: $search-gray-r080 !important;
    line-height: $line-height-18 !important;
  }
}

.#{$search-prefix}-result__highlight-container-grid {
  margin-top: -$andes-spacing-8;
  margin-bottom: $andes-spacing-8;
}

.#{$search-prefix}-item__group--price-grid-container {
  margin-bottom: 13px !important;

  .#{$search-prefix}-item__group--price-grid {
    .#{$search-prefix}-price__front-price {
      font-size: $font-size-12 !important;
      font-weight: $font-weight-normal !important;
      color: $search-gray-r090 !important;
    }
  }
}

.#{$search-prefix}-item__attributes-container-grid {
  margin: 0 0 $andes-spacing-4 0 !important;

  .#{$search-prefix}-item--location {
    font-size: $font-size-14 !important;
    font-weight: $font-weight-normal !important;
    color: $search-gray-r050 !important;
  }
}

.#{$search-prefix}-item__official-store-grid {
  padding-top: $andes-spacing-12;

  .#{$search-prefix}-official-store-label {
    font-size: $font-size-14 !important;
    font-weight: $font-weight-normal !important;
    color: $search-gray-r050 !important;
    display: flex;
    align-items: center;

    &__cockade {
      margin-left: $search-spacing-4;
    }
  }
}

.#{$search-prefix}-item__possession-container-grid {
  margin-bottom: $andes-spacing-4;

  .#{$search-prefix}-item__possession-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__location-container-grid {
  margin-bottom: $andes-spacing-4;

  .#{$search-prefix}-item__location-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__media-tag-container-grid.flex-end {
  justify-content: flex-end;
}

.#{$search-prefix}-item__media-tag-container-grid {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  .#{$search-prefix}-item__highlight-label {
    margin: 0 !important;
  }

  .#{$search-prefix}-item__highlighted-label {
    font-size: $font-size-12;
    font-weight: $font-weight-normal;
    color: #3483fa;
  }
}

.#{$search-prefix}-item__rental-type-grid {
  margin-top: -$andes-spacing-8;
  margin-bottom: $andes-spacing-8;
}

.#{$search-prefix}-item__available-units-container-grid {
  .#{$search-prefix}-item__available-units-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-gray-550;
  }
}

.#{$search-prefix}-item__online-visit-container-grid {
  display: flex;
  align-items: center;
  svg {
    fill: $andes-text-color-primary;
    margin-right: $andes-spacing-8;
  }
  margin: 8px 0 4px;
  .#{$search-prefix}-item__online-visit-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-text-color-primary;
    line-height: $line-height-18;
  }
}

.#{$search-prefix}-item__attributes-container-grid {
  .#{$search-prefix}-item__attributes-grid li {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r090;
    display: flex;
    list-style: none;
    flex-wrap: wrap;

    &:first-child::after {
      content: '';
      margin-left: 0;
    }

    &:nth-child(-n + 2) {
      display: inline;
    }

    &:nth-child(2)::after {
      content: '';
    }

    &:nth-child(2)::before {
      content: '|';
      vertical-align: baseline;
      line-height: 12px;
      margin-right: 5px;
      margin-left: 0;
      font-size: 100%;
    }

    &:nth-child(3) {
      flex: 0 0 100%;
    }
  }
}

.#{$search-prefix}-item__title {
  a {
    color: inherit;
  }
}

.real-estate-agency-logo__image--grid {
  background-color: $search-white-100;
  border: solid 1px $search-gray-93;
  border-radius: $andes-spacing-4;
  object-fit: contain;
  padding: $andes-spacing-4;
  width: 53px;
  height: 40px;
  min-height: 40px;
  position: absolute;
  right: $andes-spacing-16;
  bottom: -$andes-spacing-20;
  z-index: 10;
}

// sass-lint:disable no-vendor-prefixes

.#{$search-prefix}-layout--grid,
.#{$search-prefix}-layout--gallery {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;

  .poly-card--grid-card {
    margin-bottom: 0px;
  }

  .poly-card {
    max-width: inherit;
  }

  // BASE CARD STYLES

  .#{$search-prefix}-layout__item {
    @include gallery-cards-width-medium-xsmall-screen;

    display: grid;
    flex-basis: $gallery-cards-image-width;
    flex: 1 1;
    min-width: $gallery-cards-image-width;
    width: 100%;
    max-width: $gallery-cards-image-width;
    margin-left: 0;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .#{$search-prefix}-result__wrapper {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    max-width: inherit;
    height: calc(var(--min-content-height, auto) + var(--min-image-height, auto));
    z-index: 0;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &--hidden_picture {
      .poly-card--grid-card {
        section + img.poly-component__picture {
          visibility: hidden;
        }
      }
    }

    &--large {
      &:hover {
        .poly-card--grid-card {
          height: auto;
          min-height: calc(var(--min-content-height, auto) + var(--min-image-height, auto));
        }
      }
    }

    .poly-card--grid-card {
      height: 100%;

      .poly-card__portada {
        height: var(--min-image-height, 'auto');
      }
    }
  }

  .#{$search-prefix}-result {
    border-radius: $border-radius-6;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 1px 0 $andes-gray-100, 0 -1px 2px 0 $andes-gray-100;

    &:hover {
      box-shadow: 0 25px 20px 0 $search-gray-160, 0 -1px 2px 0 $andes-gray-100;
    }

    &__image {
      border-radius: $border-radius-6 $border-radius-6 0 0;
      border-bottom: 1px solid $search-gray-1400;
      font-size: 1px;
      margin: 0;
      position: relative;
      width: 100%;
      max-height: unset;

      .andes-carousel-snapped__control {
        z-index: 5;
        height: 70%;
      }

      .andes-carousel-snapped--scroll-hidden {
        margin: 0;
        padding: 0;
      }

      &:hover,
      &:focus {
        .andes-carousel-snapped__control {
          visibility: visible;
        }
      }

      .andes-carousel-snapped__slide {
        justify-content: center;

        .ui-search-link {
          width: 100%;
        }
      }

      .andes-carousel-snapped__control {
        background: transparent;
        border: 0;
        box-shadow: none;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
        }
      }

      &.andes-carousel-snapped__control--next span {
        transform: translateY(5px);
        background-color: red;
      }

      .andes-carousel-snapped__control:focus-visible {
        @include add-focus-box-shadow;
      }

      .andes-carousel-snapped__control__icon-container,
      .andes-carousel-snapped__control--size-large {
        svg {
          width: 32px;
          height: 32px;
        }
      }

      .ui-search-result-image__element {
        @include gallery-cards-image-height-medium-xsmall-screen;

        height: 100%;
        min-height: $gallery-cards-image-height;
        max-height: $gallery-cards-image-height;
      }

      .#{$search-prefix}-link {
        display: block;
      }

      &::after {
        animation: animation-indeterminate 2.5s linear infinite both;
        animation-name: none;
        background-color: $search-gray-700;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 0;
        z-index: $z-index-s;
      }

      &--loading::after {
        animation-name: animation-indeterminate;
      }

      .carousel-container {
        border-radius: $border-radius-6 $border-radius-6 0 0;
        overflow: hidden;
      }

      .carousel-container,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100%;
        position: relative;
        width: 100%;
      }

      .slick-slider {
        align-items: center;
        display: flex;
        position: relative;
        width: 100%;
        max-height: 100%;

        .slick-slide {
          @include gallery-cards-image-height-medium-xsmall-screen;

          height: 100%;
          max-height: $gallery-cards-image-height;
        }
      }

      .prev-button,
      .next-button {
        background-color: initial;
        box-shadow: initial;

        &::after,
        &::before {
          box-shadow: 0 1px $search-gray-100;
        }
      }

      .prev-button {
        left: 0;
        top: calc(50% - 32px);
      }

      .next-button {
        right: 0;
        top: calc(50% - 32px);
      }
    }

    &-image__element {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      border-radius: $search-spacing-6 $search-spacing-6 0 0;
    }

    &__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0;

      .#{$search-prefix}-result__link-wrapper {
        width: 100%;
        flex-grow: 1;
      }

      *:last-child {
        margin-bottom: 0;
      }

      .ui-search-threshold-label,
      .ui-search-threshold-label-initial {
        font-size: 12px;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      padding: $search-spacing-22 $andes-spacing-20;
      position: unset;
      width: 100%;
    }

    // GROUPS

    .#{$search-prefix}-item__group {
      display: flex;
      flex-shrink: 0;
      width: 100%;
    }

    .#{$search-prefix}-item__group:last-child {
      margin-bottom: 0;
    }

    // PRICE

    .#{$search-prefix}-item__group--price {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 13px;
    }

    .#{$search-prefix}-item__price_label {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-24;
      font-weight: $font-weight-regular;
      margin-right: $search-spacing-10;
      line-height: 1;
    }

    .#{$search-prefix}-price {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::before,
      &::after {
        content: unset;
      }

      &__part {
        color: $search-gray-1000;
        display: flex;
        font-size: $font-size-24;
        font-weight: $font-weight-regular;
        margin-right: $search-spacing-8;

        .price-tag-symbol {
          margin-right: $search-spacing-6;
        }
      }

      &__original-value {
        align-items: center;
        color: $search-gray-700;
        font-size: $font-size-12;
        height: 8px;
        margin-bottom: 5px;
        margin-top: $andes-spacing-4;

        .price-tag-symbol {
          margin-left: $search-spacing-2;
          margin-right: $andes-spacing-4;
        }

        .andes-money-amount__currency-symbol,
        .andes-money-amount__fraction {
          font-size: $font-size-12;
        }

        .andes-money-amount__cents {
          bottom: 2px;
        }
      }

      &__second-line {
        align-items: center;
        display: flex;

        .price-tag-cents {
          line-height: 1.35;
        }
      }

      &__second-line--decimal .price-tag-cents {
        line-height: inherit;
      }

      &__second-line__label {
        line-height: 1;
        display: flex;

        .#{$search-prefix}-icon--loyalty {
          transform: translateX(-4px);
        }
      }

      &__discount {
        color: $andes-success-green;
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        line-height: 1;
      }
    }

    // INSTALLMENTS

    .#{$search-prefix}-installments {
      display: flex;
      align-items: baseline;
      flex-shrink: 0;
      flex-wrap: wrap;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: 1.35;

      .#{$search-prefix}-price {
        align-self: center;

        &__second-line {
          margin: 0;
          margin-left: 3px;
        }

        &__part {
          color: inherit;
          display: flex;
          font-size: inherit;
          font-weight: inherit;
          line-height: 1.35;
          margin: 0 0.3rem;
        }

        &__part .andes-money-amount__currency-symbol,
        .andes-money-amount__fraction {
          font-size: $font-size-14;
        }

        &__part .price-tag-symbol {
          margin-right: 0.3rem;
        }
      }

      &-prefix {
        .#{$search-prefix}-price__part {
          margin-left: 0;
        }
      }
    }

    // SHIPPING

    .#{$search-prefix}-item__group--shipping {
      align-items: flex-start;
      margin-bottom: $andes-spacing-12;
      display: block;
    }

    .#{$search-prefix}-item__shipping {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: 1;
      margin-right: 5px;
    }

    .#{$search-prefix}-item__shipping--free {
      font-weight: $font-weight-semibold;
    }

    .#{$search-prefix}-icon--full {
      display: block;
      top: 2px;
      position: relative;
      width: 44px;
    }

    .ui-pb {
      .#{$search-prefix}-icon--full {
        display: inline;
        top: auto;
        position: static;
      }
    }

    // TITLE

    .#{$search-prefix}-item__group--title {
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: $andes-spacing-12;

      .#{$search-prefix}-official-store-label {
        margin: 2px 0;
        display: flex;
        align-items: center;

        &__cockade {
          margin-left: $search-spacing-4;
        }
      }
    }

    .#{$search-prefix}-item__title {
      color: $search-gray-1000;
      font-size: $font-size-14;
      font-weight: $font-weight-normal;
      line-height: 1.3;
      max-height: 36px;
    }

    // PROMISE

    .#{$search-prefix}-item__promise__text--splitted {
      .#{$search-prefix}-item__promise__text:first-of-type {
        margin-right: -5px;
      }
    }

    .#{$search-prefix}-item__promise {
      display: flex;
      line-height: 1;
      flex-wrap: wrap;

      .#{$search-prefix}-item__fulfillment {
        margin-top: 3px;
      }

      .#{$search-prefix}-icon--full {
        width: 40px;
      }

      .#{$search-prefix}-item__promise__text {
        border-radius: $border-radius-3;
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-semibold;
        line-height: 1;
        padding: 4px 5px;
        flex-shrink: 0;
        margin-right: $search-spacing-6;
      }

      &--same_day .#{$search-prefix}-item__promise__text {
        background-color: $search-green-100;
        color: $andes-white;
      }

      &--next_day .#{$search-prefix}-item__promise__text {
        background-color: $search-green-50;
        color: $search-green-100;
      }
    }

    .#{$search-prefix}-official-store-label {
      color: $search-gray-666;
      font-size: $font-size-12;
      font-weight: $font-weight-light;
      line-height: 1;

      &:hover {
        color: $search-gray-1000;
      }
    }

    // ATTRIBUTES

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-14;
      margin-bottom: $search-spacing-14;
    }

    // RENTAL TYPE

    .#{$search-prefix}-item__group--rental-type {
      margin-bottom: $search-spacing-12;

      & .#{$search-prefix}-item__highlight-label {
        margin: 0;
      }
    }

    // BOOKMARK

    .#{$search-prefix}-result__bookmark {
      pointer-events: none;
      position: absolute;
      right: $andes-spacing-16;
      top: $andes-spacing-16;
      transition: opacity $quick-transition-duration;
      z-index: 6;

      &.#{$search-prefix}-result__bookmark-active {
        opacity: 1;
      }
    }

    .#{$search-prefix}-bookmark {
      opacity: 0;
      height: 36px;
      position: relative;
      width: 36px;
      z-index: $z-index-base;

      &--hover-disabled {
        opacity: 1;
      }

      &.#{$search-prefix}-bookmark--active {
        opacity: 1;
      }
    }

    .#{$search-prefix}-bookmark__btn {
      align-items: center;
      background: rgba($andes-white, 0.7);
      border: 0;
      border-radius: 50%;
      color: $andes-blue-500;
      content: '';
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    .#{$search-prefix}-bookmark__icon-bookmark,
    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      position: absolute;
      height: 20px;
      width: 20px;
      z-index: $z-index-s;
      margin: 0;
      top: unset;
    }

    .#{$search-prefix}-bookmark__icon-bookmark {
      fill: transparent;
      stroke: currentcolor;
    }

    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      fill: currentcolor;
      stroke: transparent;
      opacity: 0;

      &--active {
        opacity: 1;
      }
    }

    // ADS

    .#{$search-prefix}-item__pub-container {
      position: relative;
      margin-bottom: 20px;
      margin-top: auto;
      width: 100%;
    }

    .#{$search-prefix}-item__pub-label {
      margin-left: auto;
      position: relative;
      display: block;
      width: fit-content;
      margin-right: 12.5px;
      line-height: 11px;
    }

    // LOCATION

    .#{$search-prefix}-item__location {
      display: -webkit-box;
      color: $search-res-location-color;
      font-size: $font-size-14;
      font-weight: $font-weight-semi-bold-light;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    // DETAILS

    .#{$search-prefix}-item__group--details {
      display: none;
      margin-bottom: $andes-spacing-12;
    }

    .#{$search-prefix}-item__details {
      color: $search-details-color;
      font-size: $font-size-14;
      font-weight: $font-weight-semi-bold-light;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: none;
    }

    // REVIEWS

    .#{$search-prefix}-item__group--reviews {
      display: flex;
      width: 104px;
      margin-bottom: $andes-spacing-12;
    }

    .#{$search-prefix}-reviews {
      margin-top: $andes-spacing-8;
    }

    // VARIATIONS

    .#{$search-prefix}-item__group--variations-text {
      margin-bottom: $andes-spacing-12;
      display: none;
    }

    // HIGHLIGHT

    .#{$search-prefix}-item__highlight-label {
      margin-top: $andes-spacing-4;
      margin-bottom: $andes-spacing-16;

      &--pricing_rebates {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .#{$search-prefix}-item__highlight-label {
      &.#{$search-prefix}-value-proposition__pill {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  // VERTICAL HIGHLIGHT

  .#{$search-prefix}-item__group--vertical-highlight {
    margin-bottom: $andes-spacing-12;

    .#{$search-prefix}-item__group__element {
      margin-bottom: $andes-spacing-12;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // HOVER STYLES
  .#{$search-prefix}-layout__item {
    &:hover {
      z-index: 2;

      .#{$search-prefix}-item__media-tag-container-grid {
        display: flex;
        margin-top: $andes-spacing-12;
      }

      .ui-search-item__group--details {
        display: flex;
      }

      .#{$search-prefix}-item__location {
        max-height: initial;
        -webkit-line-clamp: initial !important;
      }

      .#{$search-prefix}-item__details {
        max-height: initial;
        -webkit-line-clamp: initial !important;
        display: block;
      }

      .#{$search-prefix}-bookmark {
        pointer-events: initial;
        opacity: 1;
      }

      .#{$search-prefix}-variations-picker {
        display: block;
      }

      .#{$search-prefix}-variations-pill {
        display: none;
      }

      .#{$search-prefix}-item__possession {
        display: block;
      }

      .#{$search-prefix}-item__group--variations-text {
        display: block;
      }

      .#{$search-prefix}-result__highlighted-bottom,
      .#{$search-prefix}-result__highlighted-bottom-media-tag {
        visibility: visible;
      }

      .#{$search-prefix}-result--res .#{$search-prefix}-result__highlighted-bottom,
      .#{$search-prefix}-result--res .#{$search-prefix}-result__highlighted-bottom-media-tag {
        margin-top: $andes-spacing-16;
      }
    }
  }

  .#{$search-prefix}-bookmark__btn {
    &:hover {
      color: $search-blue-600;
    }
  }

  // CORE CARD STYLES

  .#{$search-prefix}-result--core {
    .#{$search-prefix}-item__group--add-to-cart {
      display: block;
      width: 100%;
      margin-top: auto;
      padding-inline: 20px;
      padding-bottom: 20px;

      .andes-input-stepper__button--increment {
        margin-bottom: 4px;
      }
    }

    .#{$search-prefix}-result__content-wrapper {
      flex-grow: 1;
      padding: $andes-spacing-20;
    }

    .#{$search-prefix}-installments {
      .#{$search-prefix}-price {
        &__second-line {
          margin: 0;
        }
      }
    }

    .#{$search-prefix}-item__group--details {
      margin-top: -1px;
    }
  }

  // MOT CARD STYLES

  .#{$search-prefix}-result--mot {
    .#{$search-prefix}-result__content {
      position: unset;
    }

    .#{$search-prefix}-item__group__element.#{$search-prefix}-link::before {
      content: '';
      height: 100%;
      left: 10%;
      position: absolute;
      top: 0;
      width: 80%;
      z-index: 0;
    }

    .#{$search-prefix}-result__content-wrapper {
      overflow: hidden;
      padding: $andes-spacing-24;
      position: unset;
    }

    .#{$search-prefix}-result__image {
      @include gallery-mot-cards-image-height-medium-xsmall-screen;

      min-height: var(--min-image-height, #{$gallery-mot-cards-image-height});
      max-height: var(--min-image-height, #{$gallery-mot-cards-image-height});

      .ui-search-link {
        border-bottom: none;

        &::after {
          animation: none;
        }
      }

      .#{$search-prefix}-result-image__element {
        @include gallery-mot-cards-image-height-medium-xsmall-screen;

        object-fit: cover;
        min-height: var(--min-image-height, #{$gallery-mot-cards-image-height});
        max-height: var(--min-image-height, #{$gallery-mot-cards-image-height});
      }

      .#{$search-prefix}-result-image__not-crop {
        object-fit: contain;
      }
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $search-spacing-18;
      margin-top: $search-spacing-2;
    }

    .#{$search-prefix}-item__group--title {
      margin-top: -1px;
    }

    .#{$search-prefix}-item__title {
      max-width: 100%;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-18;
    }

    .#{$search-prefix}-item__group--good-price {
      margin-top: -$search-spacing-14;
      margin-bottom: -$search-spacing-8;
    }

    .#{$search-prefix}-item-attributes__attribute {
      &--address {
        color: $search-gray-700;
      }
    }

    .#{$search-prefix}-item__group--location {
      display: block;
      margin-top: $andes-spacing-12;
    }

    .#{$search-prefix}-item__location {
      display: -webkit-box;
      color: $search-gray-1000;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
    }

    .#{$search-prefix}-official-store-label {
      margin: 8px 0;
      display: flex;
      align-items: center;

      &__cockade {
        margin-left: $search-spacing-4;
      }
    }

    .#{$search-prefix}-item__highlight-label--verified_vehicles {
      margin: -$search-spacing-4 0 $search-spacing-4;
    }

    .#{$search-prefix}-item__mot-pills--builder {
      margin-bottom: $andes-spacing-24;
      .ui-pb {
        gap: unset;
      }

      .ui-pb-container {
        .ui-pb-label {
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          vertical-align: unset;
        }

        .ui-pb-label-builder .icon-custom {
          vertical-align: unset;
        }

        .andes-money-amount,
        .andes-money-amount__currency-symbol,
        .andes-money-amount__fraction,
        .andes-money-amount__cents {
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          line-height: $font-size-18;
          top: 0px;
        }
      }
    }
    .#{$search-prefix}-item__mot-pills-bottom--builder {
      .ui-pb {
        gap: unset;
      }

      .ui-pb-container {
        .ui-pb-label {
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          vertical-align: unset;
        }

        .ui-pb-label-builder .icon-custom {
          vertical-align: unset;
        }

        .andes-money-amount,
        .andes-money-amount__currency-symbol,
        .andes-money-amount__fraction,
        .andes-money-amount__cents {
          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          line-height: $font-size-18;
          top: 0px;
        }
      }
    }

    .#{$search-prefix}-icon--melichoice-logo {
      position: relative;
      top: 2px;
    }
  }

  // RES CARD STYLES

  .#{$search-prefix}-result--res {
    .#{$search-prefix}-result__content-wrapper {
      overflow: hidden;
      padding: $andes-spacing-20 $andes-spacing-20 19px;
    }

    .#{$search-prefix}-result__image {
      .#{$search-prefix}-result-image__element {
        object-fit: cover;
        border-radius: $search-spacing-6 $search-spacing-6 0 0;
      }
    }

    .#{$search-prefix}-price__front-price,
    .#{$search-prefix}-item__subtitle {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      margin-top: -2px;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-18;
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $search-spacing-18;
    }

    .#{$search-prefix}-item__group--title {
      margin-bottom: $andes-spacing-16;
    }

    .#{$search-prefix}-item__subtitle {
      margin-bottom: 2px;
    }

    .#{$search-prefix}-item__title {
      font-size: $font-size-18;
      font-weight: $font-weight-semi-bold-light;
      margin-bottom: unset;
      line-height: 1;
    }

    .#{$search-prefix}-item__group--location {
      display: block;
    }

    .#{$search-prefix}-item__location {
      display: -webkit-box;
      color: $search-gray-1000;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      margin-top: -1px;
      margin-bottom: $search-spacing-6;
    }

    .#{$search-prefix}-item__possession {
      display: none;
      font-size: $font-size-12;
      color: $search-gray-1000;
      font-weight: $font-weight-regular;
    }

    .#{$search-prefix}-item__possession-no-hover {
      font-size: $font-size-12;
      color: $search-gray-666;
      font-weight: $font-weight-regular;
      margin-top: 5px;
    }

    .#{$search-prefix}-item__available-units {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      color: $andes-gray-550;
    }
  }

  // SRV CARD STYLES

  .#{$search-prefix}-result--srv {
    .#{$search-prefix}-result__content-wrapper {
      overflow: hidden;
      padding: $search-spacing-14 $andes-spacing-20 $andes-spacing-20;
    }

    .#{$search-prefix}-result__image {
      @include gallery-srv-cards-image-height-medium-xsmall-screen;

      min-height: var(--min-image-height, #{$gallery-srv-cards-image-height});
      max-height: var(--min-image-height, #{$gallery-srv-cards-image-height});

      .#{$search-prefix}-result-image__element {
        @include gallery-srv-cards-image-height-medium-xsmall-screen;

        min-height: var(--min-image-height, #{$gallery-srv-cards-image-height});
        max-height: var(--min-image-height, #{$gallery-srv-cards-image-height});
        object-fit: cover;
      }

      .#{$search-prefix}-result-image__not-crop {
        object-fit: contain;
      }
    }

    .#{$search-prefix}-item__group--title {
      margin-bottom: $andes-spacing-20;
    }

    .#{$search-prefix}-item__title {
      font-size: $font-size-20;
      font-weight: $font-weight-semi-bold-light;
      height: 100%;
      line-height: 1.25;
      margin: 0;
      max-height: 52px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .#{$search-prefix}-item__group--price {
      margin-top: -$search-spacing-2;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-top: unset;
    }

    .#{$search-prefix}-item__group--details {
      margin-top: -1px;

      .#{$search-prefix}-item__details {
        display: block;
      }
    }

    .#{$search-prefix}-item__attribute--address {
      color: $search-gray-700;
      font-size: $font-size-14;
    }

    .#{$search-prefix}-item__group--reviews {
      order: 5;
    }
  }

  // MEDIA TAG BOTTOM
  .#{$search-prefix}-result__media-tag-bottom {
    .#{$search-prefix}-item__highlight-label {
      margin-bottom: 0;
    }
  }

  .#{$search-prefix}-result__highlighted-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    visibility: hidden;
  }

  .#{$search-prefix}-result__highlighted-bottom-media-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;
    padding-right: $andes-spacing-56;
  }

  .#{$search-prefix}-item__highlighted-text {
    font-size: $andes-spacing-12;
    color: #3483fa;
  }

  // CPG CARD STYLES

  .#{$search-prefix}-result--cpg {
    .ui-search-result-image__element {
      min-height: $gallery-cards-image-height;
    }

    .#{$search-prefix}-result__content-wrapper {
      padding: $andes-spacing-20;
      flex-grow: 1;
    }

    .#{$search-prefix}-installments {
      .#{$search-prefix}-price {
        &__second-line {
          margin: 0;
        }
      }
    }

    .#{$search-prefix}-item__group--details {
      margin-top: -1px;
    }

    .#{$search-prefix}-item__group--add-to-cart {
      display: block;
      width: 100%;
      margin-top: auto;
      margin-bottom: 0;
      padding-inline: 20px;
      padding-bottom: 20px;
      z-index: 99;

      .andes-input-stepper__button--increment {
        margin-bottom: 4px;
      }
    }
  }
}

.#{$search-prefix}-layout--grid {
  gap: 0 10px;
  width: 100%;
  justify-content: space-between;

  .#{$search-prefix}-layout__item {
    margin-left: 0;
  }

  .add-to-cart-extra-space {
    display: block;
    height: 19px;
  }
}

.#{$search-prefix}-layout--grid {
  gap: 0 10px;
  width: 100%;
  justify-content: space-between;

  .#{$search-prefix}-layout__item {
    margin-left: 0;

    &--intervention {
      grid-column: 1 / -1;
      width: 100%;
    }
  }
}

.#{$search-prefix}-layout--grid.#{$search-prefix}-layout--flex-start {
  justify-content: flex-start;
  padding-left: 2px;
  gap: 0 14px;
}

.#{$search-prefix}-item__mot-pills-head--builder {
  text-transform: none;
  font-size: $font-size-12;
  line-height: $line-height-15;
  font-weight: $font-weight-normal;
  color: $search-gray-5;
}

@keyframes animation-indeterminate {
  0% {
    left: 0;
    width: 5%;
  }

  50% {
    left: 15%;
    width: 50%;
  }

  100% {
    left: 100%;
    width: 0;
  }
}

@media (width <= 1164px) {
  .#{$search-prefix}-layout--grid.#{$search-prefix}-layout--flex-start {
    gap: 0 10px;
  }
}
