@import '../../../styles/variables';

.#{$search-prefix}-reviews {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &__rating-number {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-right: $andes-spacing-8;
    color: $andes-text-color-secondary;
    position: relative;
    top: 1px;
  }
}

.#{$search-prefix}-reviews__ratings {
  display: flex;
  height: 100%;
  margin-right: 5px;
  width: 95px;
  align-items: center;

  .#{$search-prefix}-icon {
    margin-bottom: 0;
    margin-right: $andes-spacing-4;

    &--star {
      color: $andes-blue-500;
      height: 15px;
      width: 15px;
    }
  }
}

.#{$search-prefix}-reviews__amount {
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  color: $andes-text-color-secondary;
}
