@import '../../../styles/variables';
@import '../../../styles/mixins';

// sass-lint:disable nesting-depth, no-vendor-prefixes

$result-height: 218px;
$result-image-height: 160px;
$result-image-width: 160px;
$result-image-total-width: $result-image-width + 48px;

.#{$search-prefix}-results--without-disclaimer {
  .#{$search-prefix}-layout--stack {
    .#{$search-prefix}-layout__item:last-child {
      .#{$search-prefix}-result {
        border-radius: 0 0 $border-radius-4 $border-radius-4;
      }
    }
  }
}

.#{$search-prefix}-layout--stack {
  display: flex;
  flex-direction: column;
  width: 100%;

  .#{$search-prefix}-layout__stack {
    display: flex;
  }

  // BASE CARD STYLES
  .#{$search-prefix}-layout__item {
    border-bottom: thin solid $search-gray-10;
    flex-grow: 1;
    width: 100%;

    &:first-child {
      .#{$search-prefix}-result {
        border-radius: $border-radius-4 $border-radius-4 0 0;
      }
    }
  }

  .#{$search-prefix}-result__wrapper {
    display: flex;
    width: 100%;

    .poly-card--list {
      .poly-card__content {
        .poly-component__title {
          font-weight: $font-weight-light;
        }
      }
    }
  }

  .andes-card--flat {
    box-shadow: none;
  }

  .#{$search-prefix}-result {
    border-radius: 0;
    display: flex;
    flex-direction: row;
    padding: 20px 50px 20px 0;
    position: relative;
    width: 100%;

    &__image {
      box-sizing: content-box;
      margin-top: 14px;
      border-radius: $border-radius-4;
      flex-shrink: 0;
      font-size: 1px;
      height: $result-image-height;
      margin: 0;
      overflow: hidden;
      padding: 0 24px;
      width: $result-image-width;

      &:hover,
      &:focus {
        .andes-carousel-snapped__control {
          visibility: visible;
        }
      }

      .andes-carousel-snapped__container--full,
      .andes-carousel-snapped__controls-wrapper {
        height: 100%;
      }

      .andes-carousel-snapped__slide {
        justify-content: center;

        .ui-search-link {
          width: 100%;
        }
      }

      .andes-carousel-snapped__control {
        background: transparent;
        border: 0;
        box-shadow: none;

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }

      svg {
        width: 32px;
        height: 32px;
      }

      .#{$search-prefix}-result-image__element {
        object-fit: cover;
        cursor: pointer;
      }

      .#{$search-prefix}-result-image__not-crop {
        object-fit: contain;
      }
    }

    .ui-search-item__group__element.ui-search-link::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 208px;
      height: 100%;
      z-index: 1;
    }

    .carousel-container,
    .slick-list,
    .slick-track,
    .slick-slide {
      border-radius: $border-radius-4;
      height: 100%;
      position: relative;
      width: 100%;
    }

    .slick-slider {
      align-items: center;
      display: flex;
      height: 100%;
      position: relative;
      width: 100%;
    }

    &-image__element {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    &__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-start;
      min-width: 0;
      position: relative;

      *:last-child {
        margin-bottom: 0;
      }
    }

    &__content-wrapper {
      width: calc(100% - #{$result-image-total-width});
      display: flex;
      flex-direction: column;
    }

    &__content-columns {
      order: 4;
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &__content-column {
      display: inline-block;
      vertical-align: top;
      position: relative;

      &--left {
        $increment: 6%;
        width: 47.5% + $increment;
        order: 1;
      }

      &--right {
        order: 2;
        position: relative;
      }

      &--bottom {
        order: 4;
        position: relative;
      }
    }

    .#{$search-prefix}-item__pub-container {
      order: 10;
    }

    // GROUPS

    .#{$search-prefix}-item__group {
      display: flex;
      flex-shrink: 0;
      width: 100%;
    }

    // PRICE

    .#{$search-prefix}-item__group--price {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: $search-spacing-18;

      .#{$search-prefix}-item__group__element {
        cursor: pointer;
      }

      .#{$search-prefix}-price__part-without-link {
        cursor: default;
      }

      .#{$search-prefix}-price__part-without-link + span {
        cursor: default;
      }
    }

    .#{$search-prefix}-item__price_label {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-24;
      font-weight: $font-weight-regular;
      margin-right: $search-spacing-10;
      line-height: 1;
    }

    .#{$search-prefix}-price {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__part {
        color: $search-gray-1000;
        display: flex;
        font-size: $font-size-24;
        font-weight: $font-weight-regular;
        margin-right: $andes-spacing-8;
        line-height: 1.25;

        .price-tag-symbol {
          margin-right: $search-spacing-6;
        }
      }

      &__original-value {
        align-items: center;
        color: $search-gray-700;
        font-size: $font-size-12;
        margin-top: $andes-spacing-4;

        .price-tag-symbol {
          margin-right: $search-spacing-2;
        }

        .andes-money-amount__currency-symbol,
        .andes-money-amount__fraction {
          font-size: $font-size-12;
        }

        .andes-money-amount__cents {
          top: 1px;
        }
      }

      &__second-line {
        align-items: center;
        display: flex;

        .price-tag-cents {
          line-height: 1.8em;
        }
      }

      &__second-line--decimal {
        .price-tag-billing .price-tag-cents {
          line-height: inherit;
        }
      }

      &__second-line__label {
        line-height: 1;
        display: flex;

        .#{$search-prefix}-icon--loyalty {
          margin-right: 4px;
        }
      }

      &__discount {
        color: $andes-success-green;
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        line-height: 1;
      }
    }

    // INSTALLMENTS

    .#{$search-prefix}-installments {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;

      .#{$search-prefix}-price__part {
        color: inherit;
        display: flex;
        font-size: inherit;
        font-weight: inherit;
        margin: 0 0.3rem;
        line-height: 1;

        .price-tag-symbol {
          margin-right: 0.3rem;
        }

        .andes-money-amount__fraction,
        .andes-money-amount__currency-symbol {
          font-size: $font-size-14;
        }
      }

      .#{$search-prefix}-price__second-line {
        margin: 0;

        .price-tag-cents {
          line-height: 1.2em;
        }
      }

      .#{$search-prefix}-price__part .price-tag-symbol {
        margin-right: 0.3rem;
      }

      &-prefix {
        .#{$search-prefix}-price__part {
          margin-left: 0;
        }
      }
    }

    // SHIPPING

    .#{$search-prefix}-item__group--shipping {
      align-items: flex-start;
      margin-bottom: $andes-spacing-12;
      display: block;

      &:not(.ui-search-item__group--promise) {
        @include text-crop(2px, 3px);
      }
    }

    .#{$search-prefix}-item__shipping {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: 1;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .#{$search-prefix}-item__shipping--free {
      font-weight: $font-weight-semibold;
    }

    .#{$search-prefix}-item__fulfillment {
      align-items: center;
      display: flex;
      align-self: center;
      margin-top: $search-spacing-2;

      .#{$search-prefix}-icon--full {
        height: 14px;
        width: auto;
      }
    }

    // TITLE

    .#{$search-prefix}-item__group--title {
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: $search-spacing-10;
    }

    .#{$search-prefix}-item__title {
      color: $search-gray-1000;
      font-size: $font-size-20;
      font-weight: $font-weight-light;
      line-height: 1.3;
    }

    .#{$search-prefix}-official-store-item__link {
      width: fit-content;
    }

    .#{$search-prefix}-official-store-label {
      color: $search-gray-666;
      font-size: $font-size-12;
      font-weight: $font-weight-light;
      line-height: 1;
      display: flex;
      align-items: center;

      &:hover {
        color: $search-gray-1000;
      }

      &__cockade {
        margin-left: $search-spacing-4;
      }
    }

    // PROMISE

    .#{$search-prefix}-item__promise__text--splitted {
      .#{$search-prefix}-item__promise__text:first-of-type {
        margin-right: -$andes-spacing-4;
      }
    }

    .#{$search-prefix}-item__promise {
      display: flex;
      line-height: 1;
      height: 100%;
      flex-wrap: wrap;

      .#{$search-prefix}-item__fulfillment {
        margin-top: 3px;
      }

      .#{$search-prefix}-item__promise__text {
        border-radius: $border-radius-3;
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-semibold;
        line-height: 1;
        padding: 4px 5px;
        flex-shrink: 0;
      }

      &--same_day .#{$search-prefix}-item__promise__text {
        background-color: $search-green-100;
        color: $andes-white;
      }

      &--next_day .#{$search-prefix}-item__promise__text {
        background-color: $search-green-50;
        color: $search-green-100;
      }
    }

    // ATTRIBUTES

    .#{$search-prefix}-item__group--attributes {
      margin-top: -$search-spacing-14;
      margin-bottom: $search-spacing-14;
    }

    // VARIATIONS

    .#{$search-prefix}-item__group--variations-text {
      margin-bottom: $andes-spacing-12;
    }

    .#{$search-prefix}-item__variations-text {
      font-size: 13px;
    }

    // BOOKMARK

    .#{$search-prefix}-result__bookmark {
      pointer-events: none;
      position: absolute;
      right: $andes-spacing-16;
      top: $andes-spacing-16;
      transition: opacity $quick-transition-duration;

      .#{$search-prefix}-bookmark {
        opacity: 0;

        &--hover-disabled {
          opacity: 1;
        }

        &.#{$search-prefix}-bookmark--active {
          opacity: 1;
        }
      }
    }

    .#{$search-prefix}-bookmark {
      height: 36px;
      position: relative;
      width: 36px;
      z-index: $z-index-base;
    }

    .#{$search-prefix}-bookmark__btn {
      align-items: center;
      background: rgba($andes-white, 0.7);
      border: 0;
      border-radius: 50%;
      color: $andes-blue-500;
      content: '';
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
      padding: 0;
    }

    .#{$search-prefix}-bookmark__icon-bookmark,
    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      position: absolute;
      height: 20px;
      width: 20px;
      z-index: $z-index-s;
      top: initial;
    }

    .#{$search-prefix}-bookmark__icon-bookmark {
      fill: transparent;
      stroke: currentcolor;
    }

    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      fill: currentcolor;
      stroke: transparent;
      opacity: 0;

      &--active {
        opacity: 1;
      }
    }

    // ADS

    .#{$search-prefix}-item__pub-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      padding-top: 13px;

      .#{$search-prefix}-item__pub-link {
        position: relative;
      }

      .#{$search-prefix}-item__pub-label {
        display: block;
        position: relative;
        right: -$search-spacing-22;
        bottom: 1px;
      }
    }

    // LOCATION

    .#{$search-prefix}-item__location {
      color: $search-res-location-color;
      font-size: $font-size-12;
      line-height: $line-height-16;
      font-weight: $font-weight-semi-bold-light;
    }

    // DETAILS

    .#{$search-prefix}-item__details {
      color: $search-details-color;
      font-size: $font-size-12;
      line-height: $line-height-16;
      font-weight: $font-weight-semi-bold-light;
      margin-bottom: 9px;
    }

    // REVIEWS

    .#{$search-prefix}-item__group--reviews {
      display: flex;
      margin-bottom: 12px;
    }

    // A2C
    .#{$search-prefix}-item__group--add-to-cart {
      .#{$search-prefix}-card-add-to-cart {
        width: 263.5px;
      }

      .andes-button--medium {
        padding: 7px 36px;
      }
    }
  }

  // PRODUCT ATTRIBUTES

  .#{$search-prefix}-item__product-attributes {
    font-size: $font-size-14;
    font-weight: $font-weight-light;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 300px;
  }

  // HIGHLIGHT LABELS
  .#{$search-prefix}-item__highlight-label {
    margin-top: $andes-spacing-4;
    margin-bottom: $andes-spacing-12;

    &--pricing_rebates {
      margin: 0;
      margin-bottom: 0;
    }

    &--repurchase {
      margin: 0;
    }
  }

  // VERTICAL HIGHLIGHT

  .#{$search-prefix}-item__group--vertical-highlight {
    margin-bottom: $andes-spacing-12;

    .#{$search-prefix}-item__group__element {
      margin-bottom: $andes-spacing-12;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // GROUP ORDER

  .#{$search-prefix}-item__group--title {
    order: 0;
  }

  .#{$search-prefix}-item__group--price {
    order: 1;
  }

  .#{$search-prefix}-item__group--shipping {
    order: 2;
  }

  .#{$search-prefix}-item__group--rental-type {
    order: 2;
  }

  .#{$search-prefix}-item__group--attributes {
    order: 3;
  }

  .#{$search-prefix}-item__group--location {
    order: 4;
  }

  .#{$search-prefix}-item__group--details {
    order: 4;
  }

  .#{$search-prefix}-item__group--available-units {
    order: 5;
  }

  .#{$search-prefix}-item__group--reviews {
    order: 5;
  }

  .#{$search-prefix}-result__content-column--left {
    order: 2;
  }

  .#{$search-prefix}-result__content-column--right {
    order: 3;
  }

  // HOVER STYLES

  .#{$search-prefix}-layout__item {
    &:hover {
      .#{$search-prefix}-bookmark {
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  .#{$search-prefix}-bookmark__btn {
    &:hover {
      color: $andes-blue-700;
    }
  }

  // CORE CARD STYLES

  .#{$search-prefix}-result--core {
    .#{$search-prefix}-result__image {
      .#{$search-prefix}-result-image__element {
        object-fit: contain;
      }
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $andes-spacing-12;
    }

    .#{$search-prefix}-item__group--price .#{$search-prefix}-installments {
      .#{$search-prefix}-price {
        margin: 0;

        &__second-line {
          margin: 0;
          margin-bottom: 1px;
        }
      }
    }

    .#{$search-prefix}-item__group--details {
      margin-top: -1px;

      .#{$search-prefix}-item__details {
        color: $search-gray-666;
        font-size: $font-size-14;
      }
    }

    .#{$search-prefix}-result__content-column {
      &--right {
        top: 7px;
        width: 285px;
        min-height: 16px;
      }

      &--extra-padding {
        padding-top: 19px;
      }

      @media (width<=1127px) {
        // Para este tam la columna izquierda debe ocupar todo el ancho
        &--left {
          width: 100%;
        }

        // Para este tam la columna derecha pasa a colocarse debajo de la izquierda
        &--right {
          bottom: 0;
          top: $andes-spacing-20;
          margin-bottom: $andes-spacing-20;

          .#{$search-prefix}-variations-picker:first-child {
            margin-top: 0;
          }
        }

        &--extra-padding {
          padding-top: 0;
        }
      }
    }

    .#{$search-prefix}-variations-picker {
      .andes-dropdown__trigger {
        max-width: 100%;
      }
    }
  }

  // MOT CARD STYLES

  .#{$search-prefix}-result--mot {
    .#{$search-prefix}-item__group--title {
      margin-bottom: $search-spacing-14;

      .#{$search-prefix}-item__title {
        max-width: 100%;
      }
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $andes-spacing-16;
      margin-top: -$andes-spacing-4;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-top: -3px;
      margin-bottom: $search-spacing-6;
    }

    .#{$search-prefix}-item__group--location {
      display: block;
      margin-top: 1px;
    }

    .#{$search-prefix}-item__location {
      color: $search-gray-666;
      font-size: $font-size-14;
    }

    .#{$search-prefix}-official-store-label {
      margin: 8px 0;
    }
  }

  // RES CARD STYLES

  .#{$search-prefix}-result--res {
    .#{$search-prefix}-item__group--price {
      margin-bottom: 9px;
    }

    .#{$search-prefix}-price__front-price,
    .#{$search-prefix}-item__subtitle {
      color: $search-gray-1000;
      display: flex;
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
    }

    .#{$search-prefix}-item__group--title {
      margin-bottom: $search-spacing-38;
      margin-top: $search-spacing-2;

      .#{$search-prefix}-item__title {
        font-size: $font-size-20;
        font-weight: $font-weight-semi-bold-light;
        margin-bottom: unset;
        line-height: 1;
      }
    }

    .#{$search-prefix}-item__group--development-title {
      margin-bottom: 13px;
    }

    .#{$search-prefix}-item__group--attributes {
      margin-bottom: $search-spacing-2;
      margin-top: unset;
    }

    .#{$search-prefix}-item__group--location {
      display: block;

      .#{$search-prefix}-item__location {
        color: $search-gray-1000;
        font-size: $font-size-14;
        font-weight: $font-weight-light;
        margin-bottom: $search-spacing-6;
      }
    }

    // AVAILABLE UNITS
    .#{$search-prefix}-item__group--available-units {
      margin-bottom: $andes-spacing-4;
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      color: $andes-gray-550;
      line-height: 15px;
      display: block;
      margin-top: $andes-spacing-4;
    }
  }

  // SRV CARD STYLES

  .#{$search-prefix}-result--srv {
    .#{$search-prefix}-item__group--title {
      margin-bottom: $andes-spacing-16;
      margin-top: -$search-spacing-2;

      .#{$search-prefix}-item__title {
        display: -webkit-box;
        font-size: $font-size-20;
        font-weight: $font-weight-semi-bold-light;
        height: 100%;
        line-height: 1.25;
        margin: 0;
        max-height: 52px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .#{$search-prefix}-item__group--price {
      margin-bottom: $andes-spacing-20;

      .#{$search-prefix}-price__part {
        &.price-tag {
          margin-top: -5px;
        }

        &:not(.price-tag) {
          font-size: $font-size-18;
          font-weight: $font-weight-light;
        }
      }
    }

    .#{$search-prefix}-item__group--details {
      margin-top: -1px;

      .#{$search-prefix}-item__details {
        color: $search-gray-666;
        font-size: $font-size-14;
      }
    }

    .#{$search-prefix}-item__group--reviews {
      display: flex;
    }
  }

  li:has(> .ui-search-carousel--cpg) {
    display: flex;
    justify-content: center;
  }
}
