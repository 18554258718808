@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-installments {
  &-prefix {
    color: $andes-black;
    display: inline-flex;
    margin: 0 0.3rem 0 0;
    align-items: baseline;

    .#{$search-prefix}-price__part {
      margin-left: 0;
    }
  }

  &-pill {
    background: $andes-green-100;
    border-radius: 3px;
    color: $andes-green-500;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    margin: 0 4px 0 0;
    padding: 3px 5px;
  }
}

.ui-search-result {
  .ui-search-item__group__element {
    .#{$search-prefix}-installments-pill {
      margin: 0 4px 3px 0;
    }
    &.#{$search-prefix}-installments-pill-space {
      margin: 4px 0;
      display: inline;
    }
  }
}

.ui-search-layout--grid {
  .ui-search-result {
    .#{$search-prefix}-installments-pill {
      margin: 0 4px 3px 0;
    }
  }
}
