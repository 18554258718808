@import '../../../../styles/variables';
@import '../../../../components-v2/sidebar/styles/dropdown.desktop';

.#{$search-prefix}-price-filter {
  display: flex;
  flex-direction: row;

  .andes-form-control--textfield .andes-form-control__control {
    min-height: 24px;
  }

  .#{$search-prefix}-price-filter-container:first-child::after {
    color: $search-gray-700;
    content: '\2014';
    float: left;
    font-size: $font-size-12;
    line-height: 2px;
    margin: 0 5px;
    padding: 7px 0;
  }

  .andes-dropdown__trigger {
    padding-left: 0 !important;

    .andes-dropdown__display-values {
      text-align: left;
    }
  }

  .andes-floating-menu {
    max-height: 147px;
  }

  .andes-list__item {
    height: auto;

    &--size-medium .andes-list__item-first-column {
      padding: $search-spacing-13 0;

      .andes-list__item-primary {
        font-size: $font-size-14;
      }
    }
  }

  .andes-dropdown.andes-dropdown--open {
    .andes-dropdown__trigger {
      box-shadow: none;
    }
  }
}

.#{$search-prefix}-price-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .andes-form-control__message {
    min-height: 0;
  }

  .andes-form-control {
    padding: 0;

    .andes-form-control__field {
      border-width: 1px;

      &:hover {
        border-width: 1px;
      }
    }
  }

  .andes-form-control--textbox {
    .andes-form-control__field {
      &:focus {
        border: 2px solid $search-dodger-blue;
      }
    }
  }

  .andes-list--selectable {
    overflow-y: scroll;
    max-height: 147px;
  }
}

.andes-form-control {
  padding: 0;
}

.#{$search-prefix}-filter-groups {
  input.andes-form-control__field.andes-form-control__field {
    font-size: $font-size-13;
    height: 25px;
    width: 84px;
    background-color: $search-gray-100;
    border-color: $search-gray-900;
    border-radius: 0.375em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield;
    }

    &::placeholder {
      font-size: 14px;
      color: $search-gray-900;
    }
  }

  .andes-form-control__label {
    font-size: $font-size-13;
    top: 0;
  }
}

.andes-form-control--textfield {
  .andes-form-control__control {
    min-height: 24px;
  }
}

.#{$search-prefix}-price-filter-action-btn:focus {
  outline: none;
}

.#{$search-prefix}-price-filter-action-btn {
  background-color: $search-dodger-blue;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  height: 24px;
  padding: 0;
  position: relative;
  align-items: center;
  width: 24px;
  margin-bottom: $search-spacing-6;
  margin-left: $andes-spacing-8;

  svg {
    margin-left: 2px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(39deg) brightness(106%) contrast(101%);
  }
}

.#{$search-prefix}-price-filter-action-btn:disabled {
  background-color: $search-gray-1100;
  pointer-events: none;
}
