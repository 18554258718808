@import '../../../../../styles/variables';

// TODO: Borrar estoy cuando se haga una versión del modal de Andes que permita recibir hijos en el header.
.andes-modal-dialog__header--scrolled {
  box-shadow: unset;
  padding: 0;
}

.#{$search-prefix}-search-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .andes-form-control__field {
    font-size: $font-size-20;
    height: 52px;
    margin: 0;
    width: 100%;

    &::placeholder {
      font-size: $font-size-20;
    }
  }

  .andes-form-control--textbox {
    max-width: 688px;
    padding: 0;
    width: 100%;
  }

  .andes-form-control--default {
    max-width: 688px;
    padding: 0;
    width: 100%;
  }

  .andes-form-control__message {
    position: absolute;
  }

  &::after {
    background: linear-gradient(to top, $andes-white 20%, rgb(255 255 255 / 0%) 80%);
    border-radius: $border-radius-4;
    bottom: 0;
    content: '';
    height: 85px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity $quick-transition-duration;
    width: 100%;
  }

  .#{$search-prefix}-icon {
    display: block;
    margin: 0 auto;
    margin-bottom: 27px;
  }
}

.#{$search-prefix}-search-modal__header {
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  justify-content: center;
  min-height: 38px;
  padding-bottom: 10px;
  position: relative;
  transition: box-shadow $quick-transition-duration;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: $andes-spacing-24;
  }
}

.#{$search-prefix}-search-modal--scrolled {
  .#{$search-prefix}-search-modal__header {
    box-shadow: 0 2px 0 0 $search-gray-150;
  }
}

.#{$search-prefix}-search-modal__footer {
  z-index: 1;
  padding: $andes-spacing-16 $andes-spacing-64 $andes-spacing-16 $search-spacing-36;
  box-shadow: 0 -2px 0 0 $search-gray-150;
}

.#{$search-prefix}-search-modal__submit-button {
  width: 160px;
  padding: 15px $andes-spacing-32;
  font-size: $font-size-18;
  height: 48px;
  font-weight: $font-weight-regular;
}

.#{$search-prefix}-search-modal__scroll-fade {
  &::after {
    opacity: 1;
  }
}

.#{$search-prefix}-search-modal__title {
  color: $search-gray-1000;
  display: inline-block;
  font-size: $font-size-20;
  font-weight: $font-weight-semibold;
  line-height: 1.2;
  padding-left: 64px;
  vertical-align: middle;
  width: 100%;
}

.#{$search-prefix}-search-modal__filter-bar {
  display: flex;
  justify-content: center;
  width: 100%;

  .andes-form-control--textfield:last-child {
    width: calc(100% - 64px);
  }
}

.#{$search-prefix}-search-modal-list {
  padding: 0 64px 64px 35px;
  overflow: auto;
  max-width: 800px;
}

.#{$search-prefix}-search-modal-filter {
  color: $search-gray-666;
  font-weight: $font-weight-regular;
  margin-bottom: 7px;

  &--multi-selection {
    margin-bottom: $search-spacing-10;

    .#{$search-prefix}-search-modal-filter-name {
      color: $search-gray-1000;
    }
  }

  &:hover,
  &:focus {
    color: $search-gray-1000;
  }
}

.#{$search-prefix}-search-modal-grid-rows {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 65px 0;
  overflow: auto;

  .#{$search-prefix}-search-modal-filter {
    flex: 1 1 33.33%;
    white-space: nowrap;
  }
}

.#{$search-prefix}-search-modal-grid-columns {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 65px 0;
  margin: 0;
  overflow: auto;
  position: relative;

  .#{$search-prefix}-search-modal-filter {
    display: inline-block;
    line-height: 1.3;
    width: 30%;
    margin: 0 $andes-spacing-20 $andes-spacing-32 0;
    flex-grow: 1;
    max-width: 30%;

    &:hover,
    &:focus {
      color: $search-gray-1000;
    }
  }
}

.#{$search-prefix}-search-modal-filter-group {
  color: $search-gray-1000;
  font-weight: $font-weight-bold;
  width: 100%;

  .#{$search-prefix}-search-modal-filters {
    border-bottom: 1px solid $search-gray-1200;
    font-size: $font-size-14;
    padding: 10px 0 32px;
    width: 94%;
    column-count: 3;
    max-width: none;

    .#{$search-prefix}-search-modal-filter {
      width: 220px;
      text-align: left;
      display: block;
    }
  }

  &:last-child .#{$search-prefix}-search-modal-filters {
    border-bottom: none;
  }
}

.#{$search-prefix}-search-modal-filters-key {
  font-size: $font-size-24;
  padding-top: 29px;
  width: 6%;
  display: block;
  font-weight: 600;
}

.#{$search-prefix}-search-modal-filter-name {
  color: #000;
  font-weight: 400;
  font-size: $font-size-14;
  transition: color $quick-transition-duration;
}

.#{$search-prefix}-search-modal-image-filter {
  width: 33%;
  display: flex;

  &__image {
    width: 54px;
    height: 40px;
  }

  &__container {
    padding: 4px;
    margin-inline-start: 0;
    display: inline-block;
    background: $andes-white;
    margin: 0 14px 10px 0;
    box-shadow: 0 2px 2px 0 $search-gray-150, 0 -1px 0 0 $search-gray-150;
    border-radius: 2px;
    width: 64px;
    height: 48px;
    margin-bottom: 38px;
    margin-right: 14px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    .#{$search-prefix}-search-modal-filter-name {
      color: $andes-black;
    }
  }
}

.#{$search-prefix}-search-modal-color-filter,
.#{$search-prefix}-search-modal-size-filter {
  display: flex;
  font-size: $font-size-14;
  line-height: 1.3;
  margin-bottom: $andes-spacing-32;
  margin-right: $andes-spacing-20;
  max-width: calc(33.33% - 20px);
  white-space: nowrap;
  width: 100%;

  &:hover {
    .#{$search-prefix}-search-modal-filter-name,
    .#{$search-prefix}-search-modal-size-sample {
      color: $search-gray-1300;
    }

    .#{$search-prefix}-search-modal-size-sample {
      border-color: $search-gray-1300;
    }
  }

  .#{$search-prefix}-search-modal-filter-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.#{$search-prefix}-search-modal-color-sample {
  flex-shrink: 0;
  height: 40px;
  margin-right: $font-size-20;
  transition: transform $quick-transition-duration;
  width: 40px;
}

.#{$search-prefix}-search-modal-size-sample {
  align-items: center;
  border: 1px solid $search-gray-700;
  border-radius: 50%;
  color: $search-gray-666;
  display: flex;
  height: 48px;
  justify-content: center;
  transition: color $quick-transition-duration, border-color $quick-transition-duration;
  width: 48px;
  white-space: break-spaces;
  word-break: break-word;
  text-align: center;
  margin-right: $search-spacing-12;
  line-height: $search-spacing-14;
}

.modal-content-not-found {
  height: 500px;
  padding-top: 90px;
}

.modal-content-not-found__text {
  font-size: $font-size-24;
  text-align: center;
  padding: 0 180px;
  line-height: 1;
  color: $search-gray-1300;
}
