.ui-search-more-info-intervention {
  &__header {
    align-items: center;
    display: flex;
  }

  &__title {
    color: $andes-text-color-primary;
    font-weight: $font-weight-semibold;
  }

  &__card {
    background-color: $andes-white;
    border-radius: $border-radius-6;
    cursor: pointer;
    display: flex;
    height: 88px;
    padding: $andes-spacing-16;
    position: relative;
  }

  &__card-content {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-4;
  }

  &__card-title {
    color: $andes-text-color-primary;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
  }

  &__card-link {
    color: inherit;
    display: block;

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &:hover,
    &:focus {
      color: inherit;
      transition: none;
    }
  }

  &__card-cta {
    color: $andes-blue-500;
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
  }

  &__footer {
    align-items: center;
    display: flex;

    & svg,
    path {
      fill: $andes-blue-500;
    }
  }

  &__cta {
    color: $andes-blue-500;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
  }
}
