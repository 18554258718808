@import '../../../styles/variables';

.#{$search-prefix}-footer-disclaimer {
  align-self: center;
  display: block;
  margin-bottom: $andes-spacing-16;

  &:last-child {
    margin-bottom: $andes-spacing-32;
  }

  .#{$search-prefix}-layout + &,
  .#{$search-prefix}-listing-disclaimer + & {
    margin-top: $search-spacing-30;
  }

  &__text {
    color: $search-gray-350;
    display: inline-block;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: 21px;
    text-align: center;

    &::before {
      content: '*';
      font-size: 19px;
      margin-left: -7px;
      position: relative;
      right: $search-spacing-1;
      top: $search-spacing-2;
    }
  }
}
