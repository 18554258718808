@import '../../../styles/variables';

// sass-lint:disable bem-depth
.#{$search-prefix}-bottom-ads__wrapper {
  width: 100%;
  text-align: -webkit-center;
  background-color: $andes-bg-secondary;
  margin-bottom: -64px;
  display: flex;
  justify-content: center;
}

.#{$search-prefix}-bottom-ads__container {
  @import '~@andes/normalize/index';

  // List
  @import '~@recommendations-fe/list/src/styles/recommendations-fe-list.desktop';

  // Card Global
  @import '~@recommendations-fe/card/src/styles/recommendations-fe-card-horizontal.desktop';

  // Card Bundles
  @import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-list-horizontal';

  width: 100%;
  max-width: 1200px;
  text-align: -webkit-auto;
  padding: 20px 0 8px;
  margin-bottom: 62px;

  .ui-recommendations-list__container--horizontal {
    .ui-recommendations-title {
      font-weight: $font-weight-semibold;
      color: $andes-black;
    }

    .ui-recommendations-subtitle {
      font-weight: $font-weight-regular;

      &:hover {
        color: $andes-blue-500;
      }
    }
  }

  @media (width >= 1024px) {
    margin-bottom: 40px;
  }
}
