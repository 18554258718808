.#{$search-prefix}-animated-switch {
  justify-content: center;
  font-weight: $font-weight-semibold;
  font-size: $font-size-14;
  line-height: 1.45;
  color: $search-gray-1000;
  width: 42px;
  display: inline-block;
  height: 22px;

  &__switch-bar {
    width: 42px;
    display: inline-block;
    height: 22px;
    border-radius: 25px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $andes-white;
      margin: 2px;
      box-shadow: -1px 1px 2px $andes-gray-250;
      position: absolute;
      left: 0;
    }

    &.animated::after {
      animation: switchOff 150ms;
    }

    &--off {
      background: $seach-background-spotlight-off;
    }

    &--on {
      background: $andes-blue-500;

      &::after {
        left: 20px;
      }

      &.animated::after {
        animation: switchOn 150ms;
      }
    }
  }

  &--small {
    width: 32px;
    height: 16px;

    .#{$search-prefix}-animated-switch__switch-bar {
      width: 32px;
      height: 16px;

      &::after {
        width: 12px;
        height: 12px;
      }

      &.animated::after {
        animation: switchOffSmall 150ms;
      }

      &--on {
        &::after {
          left: 16px;
        }

        &.animated::after {
          animation: switchOnSmall 150ms forwards;
        }
      }
    }
  }
}

@keyframes switchOn {
  from {
    left: 0;
  }

  to {
    left: 20px;
  }
}

@keyframes switchOff {
  from {
    left: 20px;
  }

  to {
    left: 0;
  }
}

@keyframes switchOnSmall {
  from {
    left: 0;
  }

  to {
    left: 16px;
  }
}

@keyframes switchOffSmall {
  from {
    left: 16px;
  }

  to {
    left: 0;
  }
}
