@import '../../../styles/variables';
@import '~@andes/list/base';
@import '~@andes/list/lib/styles/list-item-selected';

.andes-modal {
  border-radius: 4px;

  a:focus-visible {
    box-shadow: 0 0 2px 2px $search-dodger-blue;
    color: $search-dodger-blue;
  }
}

.#{$search-prefix}-modal {
  .andes-list {
    font-weight: $font-weight-light;
    border-bottom: 1px solid $andes-gray-100;

    .andes-list {
      border-bottom: none;
    }
  }

  .andes-list__item {
    color: $andes-gray-900;
    padding-top: $andes-spacing-20;
    padding-bottom: $andes-spacing-20;
    border-top: 1px solid $andes-gray-100;

    &--selected::before {
      border-left-width: 6px;
    }
  }
}

.andes-modal__close-button {
  z-index: 9999;

  &:focus {
    outline: none;
  }
}

.andes-modal__header {
  .andes-modal__title {
    font-size: 20px;
    margin-left: 32px;
    padding-top: 40px;
  }

  &--sticky {
    .andes-modal__title {
      padding-bottom: 40px;
    }
  }

  .andes-modal__close-button {
    top: 40px;
    right: 59px;
    position: fixed;
    stroke: $search-dodger-blue;
    filter: invert(50%) sepia(54%) saturate(6591%) hue-rotate(207deg) brightness(107%) contrast(96%);
  }
}

.andes-modal--full .andes-modal__header {
  .andes-modal__close-button {
    // sass-lint:disable no-vendor-prefixes //
    -webkit-tap-highlight-color: transparent;
    width: 35px;
    height: 35px;
    left: 24px;
    top: 15px;

    &::before {
      content: '';

      // sass-lint:disable max-line-length
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIGZpbGw9IiMzNDgzZmEiIHN0cm9rZT0iIzM0ODNmYSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8cGF0aCBzdHJva2Utd2lkdGg9IjAuMiIgZD0iTTYgMTUuNjY3bDkgOSAxLjMzMy0xLjY2Ny02LjMzMy02LjMzM2gxNnYtMmgtMTZsNi4zMzMtNi4zMzMtMS4zMzMtMS42Njd6Ij48L3BhdGg+Cjwvc3ZnPgo=');
      width: 35px;
      height: 35px;
      position: absolute;
      left: 3px;
      color: $andes-blue-500;
      top: 3px;
      text-indent: 0;
      transform: none;
      background-color: transparent;
    }

    &::after {
      display: none;
    }
  }
}

@media (width <= 860px) and (width >= 767px) {
  .andes-modal__content > .ui-search-search-modal {
    max-height: 35em;
    max-width: 46em;
  }

  .andes-form-control--textfield .andes-form-control__control {
    margin: 0 5%;
    width: auto;
  }

  .andes-modal__scroll {
    min-height: 565px;
  }
}

@media (width >= 860px) {
  .andes-modal__content > .ui-search-search-modal {
    max-width: 816px;
    height: 485px;
  }
}
