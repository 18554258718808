@import '../../../../styles/variables';
@import '../../../../../app/styles/mixins';

.#{$search-prefix}-filter-colors {
  @include filter-color;

  box-shadow: inset 0 0 0 1px $search-gray-150;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 24px;
  position: relative;
  transition: transform $quick-transition-duration;
  width: 24px;

  &::before {
    box-shadow: 0 0 0 3px $andes-white, 0 5px 8px $search-gray-666, 0 0 0 4px $search-gray-300;
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity $quick-transition-duration;
    top: 0;
    width: 100%;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px transparent;

    &::before {
      opacity: 1;
    }
  }
}
