@import '../../../styles/variables';

// sass-lint:disable nesting-depth
.#{$search-prefix}-value-propositions-details-modal {
  &__title {
    display: block;
    position: relative;
    text-align: center;
  }

  &__content {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    height: fit-content;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin-top: auto;
  }

  &__action {
    cursor: pointer;
  }

  &__text {
    text-align: center;
  }

  .#{$search-prefix}-action {
    &--button {
      text-align: center;

      .#{$search-prefix}-action__label {
        display: block;
        width: 100%;
      }
    }
  }

  .andes-modal__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
