@import '../../../../styles/variables';

.#{$search-prefix}-rebates {
  margin-bottom: $search-spacing-12;

  &-rebate {
    margin-bottom: $search-spacing-4;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__pill {
      margin-right: $search-spacing-6;
      font-size: $font-size-14;
      border-radius: $search-blue-pill-radius;
      font-weight: $font-weight-semibold;
      line-height: $font-size-18;
      margin-bottom: $search-spacing-2;
    }

    &__text {
      font-size: $font-size-12;
      color: $search-gray-450;
      display: table-cell;
      padding-left: $search-spacing-6;
    }

    &__text_nl {
      font-size: $font-size-12;
      color: $search-gray-450;
      font-weight: $font-weight-regular;
      margin-bottom: $search-spacing-2;
    }
  }

  .#{$search-prefix}-item__highlight-label__container {
    display: block;

    // sass-lint:disable bem-depth
    .ui-search-item__highlight-label__text {
      padding: $search-spacing-1 $search-spacing-4;
    }
  }
}

.#{$search-prefix}-price__discount_prefix {
  color: $search-gray-r080;
  font-size: $font-size-14;
  margin-right: $search-spacing-4;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
