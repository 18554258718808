@import '../../styles/variables';

@mixin space-inter-wording {
  &::after {
    content: '';
    padding-right: 0.42ch;
  }
}

.#{$ui-pills-builder}-label-builder {
  display: inline;
  line-height: 1;

  .#{$ui-pills-builder}-highlight-wrapper {
    display: inline;
    margin-bottom: 0;

    &:not(:last-child) {
      @include space-inter-wording;
    }
  }

  .price-tag {
    vertical-align: middle;
    line-height: inherit;
    clear: both;

    &::after {
      content: '\00a0';
      float: left;
    }
  }

  .icon-custom {
    vertical-align: middle;

    &::after {
      content: '\00a0';
    }
  }

  svg {
    transform: translateY(3px);

    &:not(:last-child) {
      margin: 0 4px 0 0;
    }
  }

}

.ui-pb-price__part.andes-money-amount  {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 0.1em;

  .andes-money-amount__cents  {
    position: relative;
    bottom: 6px;
  }

}

.#{$ui-pills-builder}-label {
  display: inline;
  line-height: 1;
  white-space: pre-wrap;
  vertical-align: middle;

  .#{$ui-pills-builder}-highlight & {
    vertical-align: inherit;
  }

  &:not(:last-child) {
    @include space-inter-wording;
  }
}
