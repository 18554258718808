// sass-lint:disable no-misspelled-properties
@import '../../../../../styles/variables';

.ui-search-meliplay-intervention--stack {
  display: flex;
  gap: $search-spacing-24;
  padding: $search-spacing-24;
  border-bottom: thin solid $search-gray-10;
  border-radius: 0;

  .ui-search-item__highlight-label {
    margin: 0;
  }

  .ui-search-item__highlight-label__text {
    padding: $search-spacing-3 $search-spacing-4;
    font-weight: $font-weight-semibold;
    border-radius: 3px;
    font-size: $font-size-14;
    line-height: $line-height-18;
  }

  &__image {
    width: 160px;
    height: 90px;
    object-fit: cover;
    border-radius: 6px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: $search-spacing-12;
    width: calc(100% - 208px);
    margin-bottom: $search-spacing-20;

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: $search-spacing-24;
    }
  }

  &__title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &-text {
      line-height: $line-height-25;
      font-size: $font-size-20;
      font-weight: $font-weight-light;
      color: $search-gray-1000;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  &__description {
    color: $andes-text-color-secondary;
    font-size: $font-size-14;
    line-height: $line-height-18;
  }

  &__label {
    display: flex;
    gap: $search-spacing-4;
    align-items: center;

    &-text {
      color: $search-green-100;
      font-weight: $font-weight-normal;
      font-size: $font-size-14;
      line-height: $line-height-18;
    }
  }

  &__button {
    border: 1px solid !important;
    max-width: 160px;

    .ui-search-icon--play {
      margin-right: $search-spacing-7;
    }
  }
}
