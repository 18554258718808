@import '../../../../../styles/variables';

.#{$search-prefix}-tooltip-filter {
  &.andes-tooltip-placement-right .andes-tooltip-arrow::before {
    left: 1px;
    transform: rotate(135deg);
    top: calc(50% - 0.5em);
    width: 1em;
    height: 1em;
  }

  .andes-tooltip__actions {
    .ui-search-rescue__link--decline,
    .ui-search-rescue__link--decline:first-child {
      display: flex;
      justify-content: flex-start;
      font-size: $font-size-14;
      text-decoration: underline;
      font-weight: $font-weight-semibold;
      line-height: $line-height-16;
      color: $andes-white;
      background-color: transparent;
    }
  }
}
