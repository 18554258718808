@import '~@andes/normalize/index';
@import '../../../styles/variables';
@import '../../../styles/functions';

// sass-lint:disable no-transition-all
// sass-lint:disable nesting-depth
.#{$search-prefix}-vehicle-body-selector ul {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 8px;
}

.#{$search-prefix}-vehicle-body-image {
  &--479344 {
    object-position: vehicle-body-type-small-background(hatchback);
    object-fit: none;
  }

  &--452758 {
    object-position: vehicle-body-type-small-background(sedan);
    object-fit: none;
  }

  &--452759 {
    object-position: vehicle-body-type-small-background(suv);
    object-fit: none;
  }

  &--452756 {
    object-position: vehicle-body-type-small-background(pickup);
    object-fit: none;
  }

  &--452752 {
    object-position: vehicle-body-type-small-background(monovolumen);
    object-fit: none;
  }

  &--452748 {
    object-position: vehicle-body-type-small-background(cabriolet);
    object-fit: none;
  }

  &--452749 {
    object-position: vehicle-body-type-small-background(coupe);
    object-fit: none;
  }

  &--452750 {
    object-position: vehicle-body-type-small-background(furgon);
    object-fit: none;
  }

  &--452751 {
    object-position: vehicle-body-type-small-background(light_truck);
    object-fit: none;
  }

  &--452753 {
    object-position: vehicle-body-type-small-background(minivan);
    object-fit: none;
  }

  &--452754 {
    object-position: vehicle-body-type-small-background(off_road);
    object-fit: none;
  }

  &--452760 {
    object-position: vehicle-body-type-small-background(rural);
    object-fit: none;
  }

  &--452755 {
    object-position: vehicle-body-type-small-background(van);
    object-fit: none;
  }

  &--452761 {
    object-position: vehicle-body-type-small-background(crossover);
    object-fit: none;
  }

  &--452757 {
    object-position: vehicle-body-type-small-background(sports);
    object-fit: none;
  }
}

.#{$search-prefix}-vehicle-body-item {
  width: 17%;

  &__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  &__image-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $andes-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.25s ease;
    position: relative;

    &:hover {
      box-shadow: 0 2px 2px $search-gray-r020;

      .ui-search-vehicle-body-item__overlay-image {
        display: block;
      }
    }
  }

  &__image {
    width: 75px;
    height: 32px;
    position: relative;
    object-fit: none;
  }

  &__overlay-image {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $search-gray-r010;
    border-radius: 50%;
    z-index: 1;
    display: none;

    &:hover {
      box-shadow: 0 2px 2px $search-gray-r020;
    }
  }

  &__name {
    color: $search-gray-1000;
    margin-top: 13px;
    font-weight: $font-weight-semibold;
    font-size: $font-size-22;
    width: 100%;
    text-align: center;
  }

  @media (width <= 773px) {
    width: 30%;
  }
}

.#{$search-prefix}-vehicle-body-viewmore {
  display: flex;
  justify-content: center;
  width: 17%;

  .ui-search-modal__link {
    width: 100px;
    height: 100px;
    background-color: $andes-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-36;
    flex-shrink: 0;
    transition: all 0.25s ease;

    &:hover {
      box-shadow: 0 2px 2px $search-gray-r020;
      background-color: $search-gray-10;
    }
  }

  &__modal {
    .andes-modal__content {
      min-height: 400px;
    }

    .ui-search-search-modal__header {
      z-index: 500;
    }
  }

  @media (width <= 773px) {
    width: 30%;
  }
}

.#{$search-prefix}-search-modal {
  .#{$search-prefix}-vehicle-body-item {
    width: 175px;
    height: 142px;
    margin: 0 $andes-spacing-24;
    flex-grow: 1;
    align-content: baseline;

    &__image-container {
      width: 100%;
      max-width: 175px;
      height: 72px;

      &:hover {
        box-shadow: none;
        background-color: transparent;
      }
    }

    &__overlay-image {
      background: transparent;
      box-shadow: none;
    }

    &__image {
      width: 100%;
      height: 100%;
    }

    &__name {
      width: 100%;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      margin-top: $search-spacing-10;
    }
  }

  .#{$search-prefix}-vehicle-body-image {
    &--479344 {
      object-position: vehicle-body-type-background(hatchback);
    }

    &--452758 {
      object-position: vehicle-body-type-background(sedan);
    }

    &--452759 {
      object-position: vehicle-body-type-background(suv);
    }

    &--452756 {
      object-position: vehicle-body-type-background(pickup);
    }

    &--452752 {
      object-position: vehicle-body-type-background(monovolumen);
    }

    &--452748 {
      object-position: vehicle-body-type-background(cabriolet);
    }

    &--452749 {
      object-position: vehicle-body-type-background(coupe);
    }

    &--452750 {
      object-position: vehicle-body-type-background(furgon);
    }

    &--452751 {
      object-position: vehicle-body-type-background(light_truck);
    }

    &--452753 {
      object-position: vehicle-body-type-background(minivan);
    }

    &--452754 {
      object-position: vehicle-body-type-background(off_road);
    }

    &--452760 {
      object-position: vehicle-body-type-background(rural);
    }

    &--452755 {
      object-position: vehicle-body-type-background(van);
    }

    &--452761 {
      object-position: vehicle-body-type-background(crossover);
    }

    &--452757 {
      object-position: vehicle-body-type-background(sports);
    }
  }
}

.#{$search-prefix}-vehicle-body-selector {
  @media (width <= 773px) {
    width: 70%;
    align-self: center;
  }
}
