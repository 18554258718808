.#{$search-prefix}-discount-message {
  margin-bottom: $search-spacing-24;

  .andes-thumbnail-container {
    left: $font-size-12;
    position: relative;
    top: $font-size-12;

    path {
      fill: $search-green-200;
    }
  }

  .andes-message__thumbnail-container {
    background-color: $search-white-100;
    border-radius: 50%;
  }
}
