@import '../../../styles/variables';

.#{$search-prefix}-reputation-scale {
  align-items: flex-start;
  display: flex;
}

.#{$search-prefix}-reputation-scale__level {
  flex-shrink: 1;
  height: 8px;
  margin-right: $andes-spacing-4;
  overflow: hidden;
  opacity: 0.3;
  width: 100%;

  &:first-child {
    border-top-left-radius: $border-radius-4;
    border-bottom-left-radius: $border-radius-4;
  }

  &:last-child {
    border-top-right-radius: $border-radius-4;
    border-bottom-right-radius: $border-radius-4;
    margin-right: none;
  }

  &--1 {
    background-color: $search-reputation-level-1;
  }

  &--2 {
    background-color: $search-reputation-level-2;
  }

  &--3 {
    background-color: $search-reputation-level-3;
  }

  &--4 {
    background-color: $search-reputation-level-4;
  }

  &--5 {
    background-color: $search-reputation-level-5;
  }

  &--active {
    height: 9px;
    opacity: 1;
  }
}
