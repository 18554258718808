@import '../../../../styles/variables';

.#{$search-prefix}-filter-official-store {
  width: 255px;

  &__image {
    width: 54px;
    height: 40px;
  }

  &__tooltip {
    width: 100px;
    height: auto;
    padding: 5px 10px;
    text-align: center;

    a {
      color: $search-gray-666;
      font-size: $font-size-12;
      line-height: $font-size-12;
    }
  }

  &__container {
    padding: 4px;
    margin-inline-start: 0;
    display: inline-block;
    background: $andes-white;
    margin: 0 14px 10px 0;
    box-shadow: 0 2px 2px 0 $search-gray-150, 0 -1px 0 0 $search-gray-150;
    border-radius: 2px;
    width: 64px;
    height: 48px;
  }
}
