@import './variables';
@import './functions';

.ui-pb {
  margin-bottom: 10px;
  flex-direction: column;
}

.meli-plus,
.meli_plus,
.meliplus {
  &-melicoin {
    .ui-pb-sample {
      background: getColorPill('yellow');
      color: getColorPill('brown');
    }
  }

  &-off-sample {
    .ui-pb-sample {
      background-color: getColorPill('dark-blue');
      color: getColorPill('blue');
    }
  }

  .ui-pb-highlight-wrapper {
    &::after {
      display: inline-block;
      width: 4px;
      height: auto;
    }
  }
}

.fulfillment-pill {
  .ui-pb-icon {
    max-height: $andes-spacing-12;
    transform: translateY($andes-spacing-4);
  }
}

.ui-tradein-pill {
  .ui-pb-label {
    margin-left: 2.4px;
    font-weight: $font-weight-semibold;
  }
}

.ui-meliplus-pill.meliplus--actived {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
}

.ui-pb-container .ui-pb-label-builder {
  width: 100%;
}

.ui-pb-container .ui-pb-label-builder.shops {
  width: auto;
  white-space: nowrap;
}

.ui-pb-container .ui-promotions-pill.ui-pb-highlight-wrapper {
  &.coupon,
  &.volume,
  &.rebates {
    margin-bottom: $andes-spacing-8;
  }
}
