.#{$search-prefix}-threshold-label {
  display: block;
  margin-bottom: 8px;
  animation: fadeIn 0.3s ease-in-out;
}

.#{$search-prefix}-threshold-label-initial {
  display: block;

  &--stack, &--grid {
    margin-bottom: 8px;
  }
}

.ui-search-icon--full-super {
  display: inline-block;
  height: 14px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
