.#{$search-prefix}-card-add-to-cart {
  z-index: 9;
  position: relative;
  min-height: 55px;

  &__minimum-label {
    color: $search-gray-666;
    font-size: $font-size-12;
  }
}

// Reset close button position for page X modal
.ui-ms-iframe-modal--desktop {
  .andes-modal__close-button {
    top: 16px;
    right: 24px;
  }
}
