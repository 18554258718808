@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-price-details {
  display: flex;
  align-items: center;
  font-weight: $font-weight-light;

  .#{$search-prefix}-price--size-tiny {
    color: inherit;
  }

  .#{$search-prefix}-price--size-tiny .#{$search-prefix}-price__part.price-tag {
    color: inherit;
    font-weight: $font-weight-light;

    .price-tag-symbol {
      margin-right: 0.2em;
    }

    .price-tag-cents {
      font-weight: $font-weight-light;
      line-height: 1;
      padding-top: 0;
    }
  }
}
