@import '../../../../styles/variables';

.#{$search-prefix}-variations-pill__container {
  position: relative;

  .#{$search-prefix}-variations-pill {
    display: block;
    background: $andes-white;
    border: 1px solid $andes-bg-primary;
    border-radius: $border-radius-4;
    color: $search-gray-700;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    padding: 0 $andes-spacing-8;
    position: absolute;
    top: -30px;
    left: 0;
    z-index: $z-index-m;
  }
}
