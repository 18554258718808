@import '../../../styles/variables';

.#{$search-prefix}-listing-disclaimer {
  display: flex;
  width: 100%;

  &--grid {
    margin: -$andes-spacing-8 0 $andes-spacing-32;
    border-radius: 0;
  }

  &--stack {
    margin-bottom: 32px;

    & + .#{$search-prefix}-pagination {
      .#{$search-prefix}-pagination.andes-pagination {
        margin-top: 0;
      }
    }
  }

  &__card {
    display: flex;
    background-color: $andes-white;
    padding: 11px 15px 7px;
    border-radius: $border-radius-4;
    margin: 0 22px 22px;
    width: 100%;
  }

  &__card--grid {
    margin: 0;
  }

  &__card--stack {
    margin: 8px 0 0;
    border-radius: $border-radius-4;
  }

  &__icon {
    transform: translateY(-1px);
  }

  &__text {
    font-size: $font-size-12;
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
    display: inline-block;
    width: calc(100% - 26px);
    margin-left: 9px;
    position: relative;
    bottom: 1px;
    line-height: 15px;
  }
}
