@import '../../../../../styles/variables';

.#{$search-prefix}-filter-groups {
  clear: both;
  margin-top: $search-spacing-36;
}

.#{$search-prefix}-filter-row {
  display: flex;
  flex-direction: row;

  &.wrap {
    flex-wrap: wrap;
  }
}

.#{$search-prefix}-filter-size {
  border: 1px solid $search-gray-700;
  box-sizing: border-box;
  display: block;
  height: 24px;
  min-width: 24px;
  padding: 0 8px;
  text-align: center;
  line-height: 22px;
  border-radius: 12px;
  color: $search-gray-666;
  font-size: $font-size-12;
}

.#{$search-prefix}-filter-container-value {
  width: 150px;
  margin-right: 15px;
  margin-bottom: 30px;
}

.#{$search-prefix}-filter-container {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  margin: 0 0 6px;

  .#{$search-prefix}-link {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
  }
}

.#{$search-prefix}-filter-name {
  color: $search-gray-666;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  margin-left: 0;
  float: left;
}

.#{$search-prefix}-filter-results-qty {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  color: $search-gray-700;
  margin-left: 6px;
  float: left;
}

.#{$search-prefix}-filter-dt-title {
  font-weight: $font-weight-semibold;
  font-size: $font-size-base;
  margin-bottom: 10px;
  line-height: $line-height-s;
  color: $search-gray-1000;

  &.#{$search-prefix}-icon-label {
    display: block;
  }

  .#{$search-prefix}-icon {
    display: inline-block;

    &--cockade {
      width: 14px;
      position: relative;
      bottom: -1px;
    }
  }
}

.#{$search-prefix}-filter-dd {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 12px;
  margin-inline-start: unset;
}

.#{$search-prefix}-filter-dd-color {
  margin-right: 12px;
}

.#{$search-prefix}-filter-dd-size {
  margin-bottom: 12px;
  padding: 0;

  :hover {
    color: $search-gray-1300;
    border-color: $search-gray-1300;
    font-weight: $font-weight-semi-bold-light;
  }
}

.#{$search-prefix}-filter-dd--official-store {
  background-color: $andes-white;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 $search-gray-150;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  margin-right: 14px;
  overflow: hidden;
  padding: 4px;
  width: 64px;

  .#{$search-prefix}-filter--official-store {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .#{$search-prefix}-filter__store-logo {
    border-radius: 2px;
    font-size: 10px;
    height: 100%;
    margin: 0;
    object-fit: cover;
    width: 100%;
  }
}

.ui-search-filter-range--year {
  margin-top: 25px;
}

.#{$search-prefix}-filter-dl {
  width: 100%;
  padding-right: $andes-spacing-16;
  margin-bottom: 30px;

  .#{$search-prefix}-modal__link {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }
}

.#{$search-prefix}-filter-tooltip {
  font-size: $font-size-12;
  padding: 8px 0;

  &.andes-popper {
    width: 10em;
  }

  .andes-tooltip__content {
    margin-right: 0;
    text-align: center;
  }
}

.andes-tooltip__text {
  font-size: $font-size-14;
}

.#{$search-prefix}-filter-header {
  padding: 39px 0 24px;
}

.#{$search-prefix}-filter-header-fixed {
  display: block;
  position: fixed;
  width: 100%;
  background: $search-gray-100;
  box-shadow: 0 2px 2px 0 $search-gray-350;
}

.#{$search-prefix}-filter-title {
  display: inline-block;
  vertical-align: middle;
  font-size: $font-size-20;
  font-weight: $font-weight-semibold;
  line-height: 1.2;
  color: $search-gray-1000;
  padding-left: 64px;

  .andes-list__item {
    font-size: $font-size-14;
  }
}

.#{$search-prefix}-filter-icon {
  display: flex;

  &--full {
    width: 40px;
    height: auto;
    max-height: 14px;
    fill: $search-green-100;
    margin: $andes-spacing-4 $search-spacing-2 0 0;
  }
}
