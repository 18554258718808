@import '../../../styles/variables';

.andes-tooltip {
  &-transition {
    &-enter {
      transition: none;
    }

    &-leave {
      display: none;
    }

    &-enter-active {
      transition: none;
    }

    &-leave-active {
      display: none;
    }
  }

  // sass-lint:disable class-name-format
  &-placement-bottomLeft,
  &-placement-bottomRight,
  &-placement-bottom,
  &-placement-leftTop,
  &-placement-rightTop,
  &-placement-right {
    &.andes-tooltip-transition {
      &-enter {
        transition: none;
      }

      &-leave {
        display: none;
      }

      &-enter-active {
        transition: none;
      }

      &-leave-active {
        display: none;
      }
    }
  }
}
