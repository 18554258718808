.#{$search-prefix}-top-keywords {
  padding: $search-spacing-14 0 $search-spacing-30;

  &__item {
    display: inline;
    font-size: 14px;
    color: $search-gray-1000;

    &::after {
      content: '-';
      padding: 0 8px;
    }

    &:first-of-type {
      font-weight: $font-weight-bold;

      &::after {
        content: ':';
        padding: 0 6px 0 0;
      }
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }

  &__list {
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
  }

  &__link {
    color: $search-gray-1000;

    &:active,
    &:visited {
      color: $search-gray-1000;
    }

    &:hover {
      color: $andes-gray-900;
      text-decoration: none;
    }
  }
}
