@import '../../../styles/variables';

.#{$search-prefix}-cross-site-modal {
  &__message {
    color: $search-gray-1000;
    font-size: $font-size-18;
    line-height: 1.2em;
    margin-top: 15px;
  }

  &__button {
    border-radius: 4px;
    font-size: $font-size-18;
    font-weight: $font-weight-regular;

    &:first-of-type {
      margin-top: 35px;
      line-height: 18px;
    }

    &:last-of-type {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__message--strong {
    display: inline-block;
    font-weight: $font-weight-bold;
    margin-left: 5px;
  }

  &.andes-modal {
    width: 475px;
    padding: 62px 0;
    align-items: center;
    text-align: center;
    border-radius: 6px;
  }
}
