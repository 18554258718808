@import '../../../../styles/variables';

.#{$search-prefix}-item__spotlight {
  display: flex;

  // sass-lint:disable no-misspelled-properties
  gap: 6px;
}

.#{$search-prefix}-destaque__compatible__icon {
  margin-top: 7px;
}

.#{$search-prefix}-item__spotlight__highlight {
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 1px;

  .#{$search-prefix}-icon--elipse-check {
    margin-right: 2px;
    margin-bottom: -3px;
  }
  .#{$search-prefix}-icon--url {
    margin-right: 5px;
  }
}
