@import '../../../../styles/variables';

.#{$search-prefix}-variations-picker--grid {
  position: relative;
  z-index: 1;
}

.#{$search-prefix}-item__variations-text {
  color: $search-gray-666;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
}

.andes-dropdown__popover {
  .andes-list__item-image {
    margin-right: 0;
    border-radius: 3px;
    width: 38px;
    height: auto;
  }
}
