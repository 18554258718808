li:has(> div > .ui-compats-wrapper__sticky) {
  position: sticky;
  top: -0.1px;
  z-index: +30;
}

.ui-compats-wrapper__sticky_shop {
  top: 90px;
}

.ui-compats-wrapper {
  margin-bottom: $andes-spacing-12;
  .ui-compats-header__container-message {
    margin: 0px;
  }

  &__sticky {
    position: sticky;
    top: -0.1px;
    z-index: +30;
  }

  &__shadow-on {
    box-shadow: 0 6px 16px rgba($andes-black, 0.16);
  }

  &__animation-message {
    animation: messageAnimation 0.7s linear 0s 1 normal forwards;
    .ui-compats-header__container-message {
      margin: 0;
    }
    .ui-compats-header__container-message {
      .andes-message,
      .andes-message__border-color--accent {
        border-radius: 0;
      }
    }
  }

  // sass-lint:disable class-name-format //
  .ui-compats-header--COMPATS_CARD_UNKNOWN {
    margin-top: 8px;
  }
}

.ui-compats__inputs {
  .andes-list__item--size-medium .andes-list__item-first-column {
    padding: 16px 0;
  }
}

[data-site='MS'] {
  .ui-compats-wrapper__sticky {
    top: 90px;
    box-shadow: none;
  }
}

.ui-compats-header__container-message {
  .andes-message--quiet {
    background: $andes-white;
  }
}

@media (min-width: $breakpoint-desktop) {
  .ui-compats-header__container-message {
    .andes-message--quiet {
      .andes-message__buttons-container {
        margin-top: 11px;
        .andes-button--transparent:hover {
          background: transparent;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  [data-site='MS'] {
    li:has(> .ui-compats-wrapper__sticky) {
      box-shadow: none;
      top: 80px;
    }
  }
  .ui-compats-wrapper {
    &__custom {
      background-color: $andes-white;
      padding: 12px;
      margin-bottom: 0;
    }

    .ui-compats-header__container-message {
      margin: 12px;
    }
    &__animation-message {
      .ui-compats-header__container-message {
        margin: 0px;
      }
    }
  }
}
@keyframes messageAnimation {
  0% {
    opacity: 0;
    transform: translateY(-250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
