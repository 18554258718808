// sass-lint:disable no-vendor-prefixes

$suggestions-border-color: #e5e5e5;

.#{$search-prefix}-suggestions {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  z-index: 12;
  background-color: $search-gray-200;
  position: relative;

  &__wrapper {
    display: block;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    z-index: 11;
    position: absolute;
    left: 4.5%;
    width: 90.85%;
    bottom: 85px;

    &::before {
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      box-shadow: 0 6px 16px 0 $andes-gray-100;
      border-radius: 5px;
      z-index: 9;
    }

    &::after {
      background: $search-gray-1600;
      content: '';
      display: block;
      position: absolute;
      height: 12px;
      width: 12px;
      z-index: 10;
      box-shadow: 0 6px 16px 0 $andes-gray-100;
      transform: translateX(-40%) rotate(-45deg);
      bottom: -6px;
      left: 49.5%;
    }

    &--animated-open {
      opacity: 1;
    }

    &--animated-close {
      opacity: 0;
    }
  }

  .andes-badge {
    transform: translateY(2px);
  }

  .andes-badge--small.andes-badge--notification .andes-message__badge--success {
    color: $search-suggestion-success-color;
  }

  .andes-badge--small.andes-badge--notification {
    height: 16px;
    width: 16px;
  }

  &__tag__link {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  &__tag__item {
    display: flex;
    margin-top: 16px;
  }

  &__header {
    border-bottom: solid 1px $search-gray-r010;
    display: flex;
    background-color: $search-gray-200;
    flex-direction: column;
    padding: 0 16px;
    margin-bottom: 16px;

    &__title {
      color: $andes-gray-900;
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      margin: 0 20px 0 0;
      padding-bottom: 14px;
      padding-left: 8px;
      width: 80%;
      position: relative;
      bottom: 1px;
    }

    &__icon {
      margin-right: 0;
    }

    &__notification {
      display: flex;
    }
  }

  &__item__link {
    max-height: 36px;
    text-decoration: none;

    &:visited {
      color: $search-gray-1000;
    }
  }

  &__item__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: $font-size-14;
    color: $search-gray-1000;

    // sass-lint:disable no-vendor-prefixes
    -webkit-line-clamp: 2;

    // sass-lint:disable no-vendor-prefixes
    -webkit-box-orient: vertical;
    height: 36px;

    &:hover {
      color: $search-gray-700;
    }
  }

  &__subtitle {
    color: $andes-gray-900;
    font-size: $font-size-16;
    letter-spacing: normal;
    padding: 0 16px;
    width: 100%;
  }

  .andes-message__text {
    border-top: solid 1px $search-gray-r010;
  }

  &__item-title {
    font-size: $font-size-14;
    max-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    // sass-lint:disable no-vendor-prefixes
    -webkit-line-clamp: 2;

    // sass-lint:disable no-vendor-prefixes
    -webkit-box-orient: vertical;
  }

  &__wrapper-tag {
    display: flex;
    padding: 16px 0 20px;
    flex-direction: column;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 16px;
  }

  &__tag {
    background-color: $andes-white;
    border-radius: 6px;
    color: $search-dodger-blue;
    font-size: 14px;
    height: 32px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    font-weight: $font-weight-regular;
    border: 1px solid $suggestions-border-color;
    padding-top: 5px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    visibility: visible;
    position: relative;
    padding: 5px 10px;
    line-height: 19px;
  }

  &__tag:hover {
    background-color: $search-gray-10;
    color: $search-dodger-blue;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    right: 57%;
    z-index: 11;
  }

  &__fade {
    background: $seach-background-fade-add-to-cart;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    border-radius: 4px;
  }

  &__close {
    border: none;
    cursor: pointer;
    position: absolute;
    right: 19px;
    top: 21px;

    .#{$search-prefix}-icon--close {
      width: 10px;
      height: 10px;
      display: block;
      fill: $andes-gray-900;
    }
  }
}

@media (width <= 1164px) {
  .#{$search-prefix}-suggestions {
    &__subtitle {
      font-size: 15px;
      padding: 0 12px;
    }

    &__wrapper-tag {
      padding: 12px 0 16px;
    }

    &__tag {
      font-size: 13px;
      padding-top: 6px;
    }

    &__header {
      padding: 0 12px;

      &__title {
        font-size: 15px;
      }
    }

    &__list {
      padding: 0 12px;
    }

    &__close {
      position: absolute;
      right: 15px;
      top: 16px;

      .#{$search-prefix}-icon--close {
        width: 8px;
        height: 8px;
      }
    }

    .andes-badge--small.andes-badge--notification {
      height: 15px;
      width: 15px;
    }
  }
}
