@import '~@andes/money-amount/index';

$container-promotion: 'container-promotion';
$label-padding: 'label-padding';
$label-text: 'label-text';

.#{$search-prefix}-coupon {
  border-radius: 2px;
  display: flex;
  width: fit-content;
  margin-bottom: 1px;

  &__label {
    display: flex;
    font-weight: $font-weight-semibold;

    &__icon {
      margin-right: 4px;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }
}

.#{$label-padding}-mobile {
  padding: 2px 6px 1px;

  svg {
    width: 13px;
    height: 11px;
  }
}

.#{$label-padding}-desktop {
  padding: 1px 4px;

  svg {
    width: 16px;
    height: 13px;
  }
}

.#{$label-text}-mobile {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
}

.#{$label-text}-desktop {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.#{$container-promotion} {
  margin-bottom: 12px;
}
