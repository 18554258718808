@import 'common';

.ui-search-more-info-intervention {
  background-color: $andes-gray-040-solid;
  border-radius: $border-radius-6;
  height: 220px;
  margin-block-end: $andes-spacing-24;

  &__header {
    border-bottom: 1px solid $andes-gray-100-solid;
    gap: $andes-spacing-8;
    padding-block: $andes-spacing-16;
    padding-inline: $andes-spacing-20;
  }

  &__title {
    font-size: $font-size-20;
  }

  &__content {
    padding-block-start: $andes-spacing-16;
    padding-inline: $andes-spacing-20;

    .andes-carousel-snapped--scroll {
      &-visible {
        .ui-search-more-info-intervention__card {
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
        }
      }

      &-hidden {
        padding: $andes-spacing-8;
        margin: -$andes-spacing-8;

        .ui-search-more-info-intervention__card {
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)
        }
      }
    }
  }

  &__card {
    gap: $andes-spacing-12;
    width: 273px;
  }

  &__footer {
    padding-block: $andes-spacing-20;
    padding-inline: $andes-spacing-20;
  }
}
