@import '~@andes/money-amount/index';

.#{$search-prefix}-melicoin {
  border-radius: 3px;
  display: flex;
  font-weight: 600;
  padding: 2px 4px;
  width: fit-content;
  background-color: $search-yellow-fff1cb;
  margin-bottom: $search-spacing-12;

  &__label {
    display: flex;
    line-height: 14px;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $search-brown-a47b2e;
  }

  &__split {
    margin-bottom: 1px;
  }

  &__spaceFinal {
    padding-right: 2px;
  }

  &__spaceStart {
    padding-left: 2px;
  }

  &__spaceMiddle {
    padding-right: 2px;
    padding-left: 2px;
  }

  .andes-money-amount--cents-comma {
    line-height: 14px;
    font-weight: 600;
  }

  .andes-money-amount__currency-symbol {
    font-size: $font-size-14;
  }

  .andes-money-amount__cents {
    font-size: $font-size-14;
  }

  .andes-money-amount__fraction {
    font-size: $font-size-14;
  }

  .#{$search-prefix}-icon--icon-melicoin {
    margin-left: 4px;
  }
}
