@import 'price-details';

.#{$search-prefix}-price-details {
  font-size: $font-size-14;
  margin-top: 3px;

  .#{$search-prefix}-price--size-tiny .#{$search-prefix}-price__part.price-tag {
    font-size: $font-size-14;
    margin-right: $andes-spacing-4;

    .price-tag-cents {
      font-size: $font-size-10;
    }
  }
}
