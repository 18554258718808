@import './highlight';

.#{$ui-pills-builder}-highlight {
  font-size: 14px;
  line-height: 21px;

  .price-tag.#{$ui-pills-builder}-price__part {
    transform: translateY(-2px);

    @-moz-document url-prefix() {
      transform: translateY(-1px);
    }
  }

  @-moz-document url-prefix() {
    line-height: 23px;
  }
}
