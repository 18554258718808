@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-item__promise__text {
  @include text-crop(3px, 3px);

  border-radius: $border-radius-3;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: $font-size-12 + 2;
  padding: 5px 4px 6px;
}

.#{$search-prefix}-item__promise {
  .#{$search-prefix}-item__promise__text {
    margin-right: $andes-spacing-8;
  }

  &--same_day .#{$search-prefix}-item__promise__text {
    background-color: $search-green-100;
    color: $andes-white;
  }

  &--turbo .#{$search-prefix}-item__promise__text {
    background-color: $search-green-100;
    color: $andes-white;
  }

  &--next_day .#{$search-prefix}-item__promise__text {
    background-color: $search-green-50;
    color: $search-green-100;
  }
}
