@import '../../../../styles/variables';

.#{$search-prefix}-item__promise-message {
  line-height: 18px;
  margin-bottom: 12px;
  display: inline-block;

  &:last-child {
    margin-bottom: 0;
  }
}
