@import '../../../../styles/variables';

.#{$search-prefix}-quick-access-intervention {
  &-container {
    position: relative;
    border-bottom: $search-spacing-1 solid rgb(0 0 0 / 4%);
    border-top: $search-spacing-1 solid rgb(0 0 0 / 4%);
    padding: $search-spacing-16 $search-spacing-24;
    border-radius: 6px;
    background-color: #fff;
    margin-bottom: $search-spacing-16;
    margin-top: $search-spacing-16;
  }

  &-separator {
    width: $search-spacing-1;
    height: $search-spacing-18;
    background: #00000040;
    margin: 0 $search-spacing-16;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
  }

  &__title {
    margin-bottom: $search-spacing-16;
    line-height: 1.22;
    color: $andes-gray-900-solid;
  }

  &__img-container {
    border: 1.2px solid #e5e5e5;
    height: 72px;
    width: 72px;
    margin-right: $search-spacing-16;
  }

  &__img-container &__icon {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__img-container &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: $search-spacing-16;
  }

  &__content-label-container {
    display: flex;
    align-items: center;
    gap: $search-spacing-4;
    margin-bottom: $search-spacing-4;
  }

  &__components-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: $search-spacing-24;

    @media (width < 1024px) {
      display: none;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &-blue_10 {
    background-color: $andes-blue-100;
  }

  &-gray-040-solid {
    background-color: $andes-gray-040-solid;
  }

  &__content-label {
    line-height: 1.28;
    color: #737373;
  }

  &__content-title {
    color: #1a1a1a;
    line-height: 1.25;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__content-subtitle {
    color: #1a1a1a;
    line-height: 1.25;
  }

  &__action {
    line-height: 1.14;
    height: $search-spacing-32;
    margin-left: $search-spacing-24;
  }

  &__action::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-base;
    transform: unset;
    background: none;
  }

  &__cockade {
    width: $andes-spacing-12;
    height: $andes-spacing-12;
  }
}
