.search-ui-seo-contents {
  margin: $andes-spacing-16 0;

  &__header {
    padding: $andes-spacing-24 !important;
    color: $andes-text-color-secondary;
  }

  &__content {
    padding: $andes-spacing-24 !important;
  }

  &__faqs-title {
    color: $andes-text-color-secondary;
    font-size: $font-size-14;
  }

  &__faqs {
    margin-top: $andes-spacing-16;
  }

  &__blogs-container {
    padding: 32px 24px 32px 24px;

    &.seo-intervention-on {
      border-top: 1px solid rgba(0, 0, 0, 0.07);
    }

    .seo-ui-card-blog-carousel {
      max-width: 1108px;
      box-sizing: content-box;

      // To support andes v7 styles
      .andes-badge {
        &__content {
          color: $andes-blue-500;
        }

        &--accent--quiet {
          background: $andes-blue-100;
          border: 0;
        }
      }
    }
  }
}
