@import '../../../styles/variables';

.#{$search-prefix}-header--exhibitor__link {
  display: block;
  width: 100%;
  height: 100%;

  &__image {
    display: block;
    width: 100%;
    height: 90px;
    object-fit: cover;
  }
}
