.wrapper-sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 12;

  .ui-ms-profile__container .ui-ms-profile__floating-menu .ui-ms-profile__item svg {
    margin-left: 0;
    padding-left: 6px;
    box-sizing: content-box;
  }
}

.eshops {
  background-color: $search-white-100;

  .ui-search-main-header-exp--eshop--title {
    margin: 0;
    padding: $andes-spacing-16 $andes-spacing-16 $search-spacing-10 $andes-spacing-16;
  }
}
