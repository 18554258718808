.#{$search-prefix}-value-proposition {
  color: $search-gray-800;
  letter-spacing: -0.2px;

  &__pills {
    display: inline-block;
  }

  &__pill {
    display: flex;
    margin-left: 3px;
    margin-top: 4px;

    &:first-child {
      margin-left: 0;
    }
  }
}
