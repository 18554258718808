@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$search-prefix}-main-header-exp {
  display: flex;
  width: 100%;
  margin-bottom: $andes-spacing-24;

  &__title {
    flex: 1;
    padding-right: $andes-spacing-48;
    margin: 0;
    font-size: 26px;
    font-weight: $font-weight-semibold;
    line-height: $font-size-32;
    word-wrap: break-word;
    color: $search-gray-1000;
    z-index: 1;
  }

  .#{$search-prefix}-search-result {
    flex: 0 1 auto;
    align-self: baseline;
    padding-top: 13px;
    margin: 0;
    text-align: right;
  }
}
