@import '../../../styles/variables';

.#{$search-prefix}-fullsnackbar {
  background-color: $andes-white;
  border-radius: 4px;
  display: flex;
  font-size: $font-size-14;
  padding: $andes-spacing-16;

  &__eshop-container {
    max-width: 40px;
    max-height: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    align-self: start;
    border: none;
    background: transparent;
  }

  .#{$search-prefix}-icon--close {
    height: 10px;
    width: 10px;
  }

  &__subtext {
    color: $search-gray-r080;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 1.35;
    margin-top: $andes-spacing-8;
    height: 18px;
  }

  &--complete-first {
    .#{$search-prefix}-fullsnackbar__progressbar {
      width: 100%;
      animation: unset;
    }
  }

  &__progressbar-container {
    height: 4px;
    margin: $andes-spacing-8 0 $search-spacing-6;
  }

  &__progressbar-sizer {
    height: 100%;
  }

  &__progressbar {
    border-radius: 6px;
    height: 100%;
    width: 0;
    animation: search-fullinfo-progress 0.4s ease-in 0.5s forwards;
  }

  &__shippingtruck-container {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100%;
    padding: 7px;
  }

  &__preicon {
    margin-right: 5px;
    margin-top: $andes-spacing-8;
    height: 18px;
    font-weight: $font-weight-semibold;
  }

  &__posticon {
    margin-left: 5px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: $search-gray-r080;

    .#{$search-prefix}-fullsnackbar__icon-text-container {
      display: flex;
    }

    .#{$search-prefix}-fullsnackbar__text-bar-container {
      width: 100%;
    }

    .#{$search-prefix}-fullsnackbar__text-container {
      width: 100%;
      margin-left: 10px;
    }

    .ui-search-fullsnackbar__icon-container:empty + .ui-search-fullsnackbar__text-container {
      margin-left: 0;
    }

    .ui-search-icon--full {
      height: $font-size-14;
      width: auto;
      vertical-align: middle;
    }

    .#{$search-prefix}-icon--supermarket-fs {
      vertical-align: middle;
    }
  }

  &__top {
    font-weight: $font-weight-semibold;
  }

  &__bottom {
    margin-top: $andes-spacing-4;
  }

  &__close {
    fill: $search-gray-350;
  }

  .#{$search-prefix}-fullsnackbar &__button-container {
    bottom: $andes-spacing-12;
    left: $andes-spacing-12;
  }

  .#{$search-prefix}-fullsnackbar &__action-button {
    width: 100%;
    height: 32px;
    margin-top: $andes-spacing-12;
  }
}

@keyframes search-fullinfo-progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.eshops-fullsnackbar.#{$search-prefix}-fullsnackbar {
  width: 348px;
  height: 100%;
  padding: $search-spacing-20 $search-spacing-20 $search-spacing-17 $search-spacing-12;
  border-radius: 6px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);

  span {
    font-weight: 400;
    line-height: $search-spacing-18;
    color: $andes-gray-900;
  }
}

.eshops-fullsnackbar .#{$search-prefix}-fullsnackbar {
  &__main {
    margin: 0px;
  }

  &__bottom {
    margin-top: $andes-spacing-8;
  }

  &__progressbar-container {
    height: 4px;
    margin: 0;
  }

  &__progressbar-sizer {
    background-color: $andes-gray-100-solid;
    display: flex;
    height: 4px;
    width: 100%;
  }
  &__progressbar {
    animation: unset;
    align-items: center;
    background-color: $andes-blue-500;
    display: flex;
    height: 4px;
    justify-content: end;
  }

  &__dot {
    background-color: $andes-blue-500;
    border-radius: 10px;
    height: 10px;
    left: 0;
    position: relative;
    width: 10px;
  }
}

.ui-search-fullsnackbar__eshop-container {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 34px;
    background-color: $andes-gray-040;
    border-radius: $border-radius-6;
    mix-blend-mode: multiply;
    z-index: 1;
  }

  a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 34px;
    z-index: 1;
  }
}
