@import '../../../../styles/variables';

.ui-search-andes-message-intervention {
  margin: $andes-spacing-24 0;

  .andes-message__text {
    padding-right: $andes-spacing-16;
  }
}

.#{$search-prefix}-zrp-disclaimer {
  background-color: $search-zrp-disclaimer;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  color: $andes-white;
  width: 100%;
  margin: 0;
  margin-bottom: 15px;

  &__full-message {
    margin-right: 5px;
  }

  &__query {
    font-weight: $font-weight-semibold;
  }

  &__link {
    color: $andes-white;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:hover,
    &:visited {
      color: inherit;
    }
  }

  .andes-message__content {
    width: 88%;
    align-self: center;
  }

  .andes-badge {
    display: flex;
    align-self: flex-start;
    margin-left: 22px;
  }

  .andes-message__close--neutral {
    right: 22px;
    top: 19px;
  }

  a,
  a:link,
  a:hover,
  a:visited {
    text-decoration: underline;
  }

  .andes-message__text {
    padding-right: 0;
  }
}
