.#{$search-prefix}-pricing-banner {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.#{$search-prefix}-pricing-banner__image {
  max-width: 885px;
  max-height: 160px;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
