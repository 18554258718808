@import '../styles/variables';

* {
  -webkit-font-smoothing: antialiased;
}

main {
  // sass-lint:disable no-vendor-prefixes //
  -webkit-font-smoothing: antialiased;
}

.#{$ui-pills-builder} {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 0;
  margin-bottom: 4px;

  .#{$ui-pills-builder}-container {
    display: flex;
    width: 100%;
  }
}

.#{$ui-pills-builder}__card-content {
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.#{$ui-pills-builder} {
  &-component-hover {
    display: none;
  }

  &:hover,
  &:focus-within {
    .#{$ui-pills-builder}-component-hover {
      display: block;
    }
  }
}
