@import './value-proposition';

.#{$search-prefix}-value-proposition {
  line-height: 13px;

  &__pills {
    margin-right: $search-spacing-4;

    .#{$search-prefix}-item__highlight-label {
      font-size: $font-size-14;
    }

    .#{$search-prefix}-item__highlight-label--VOLUME_DISCOUNT {
      font-size: $font-size-12;
    }

    .#{$search-prefix}-icon {
      top: 0;
    }
  }
}

.#{$search-prefix}-value-propositions-list {
  @include text-crop(3px, 1px);
}
