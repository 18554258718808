@import '../../../styles/variables';

$search-official-store-header-link-shadow-color: rgb(51 51 51 / 16%);
$search-official-store-header-subtitle-color: rgb(0 0 0 / 45%);

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-header--official-store {
  align-items: flex-end;
  background-color: $andes-white;
  display: flex;
  height: 180px;
  justify-content: center;
  width: 100%;

  &__banner {
    width: 100%;
    height: 180px;
    object-fit: cover;
    position: absolute;
    overflow: hidden;
    object-position: left top;
  }

  &__wrapper {
    display: flex;
    max-width: $default-body-width;
    position: relative;
    width: 100%;
    z-index: 0;
  }

  &__link {
    background-color: $andes-white;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 $search-gray-150, 0 -1px 2px 0 $search-gray-150;
    height: 98px;
    margin-bottom: -52px;
    margin-left: 10px;
    padding: 4px;
    position: relative;
    width: 130px;
  }

  &__image {
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
