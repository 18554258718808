@import './value-propositions-details-modal';

// sass-lint:disable nesting-depth
.#{$search-prefix}-value-propositions-details-modal {
  &__title {
    font-size: $font-size-24;
    width: 295px;
    line-height: 24px;
    right: 8px;
    white-space: pre-line;
  }

  &__content {
    font-size: $font-size-16;
    top: 10px;
    padding-bottom: 20px;
  }

  &__text {
    line-height: 22px;
    width: 100%;

    &:not(:first-child) {
      margin-top: 21px;
    }
  }

  .#{$search-prefix}-action {
    &--button {
      &:first-of-type {
        width: 161px;
      }

      &:last-of-type {
        width: 169px;
      }

      .#{$search-prefix}-action__label {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    &--button:focus-visible {
      outline: none;
    }
  }

  .ui-search-value-propositions-details-modal__actions {
    justify-content: center;
    align-items: center;

    // sass-lint:disable no-misspelled-properties
    gap: 10px;

    @media (width <= 445px) {
      width: 100%;

      .#{$search-prefix}-action {
        width: 100%;
      }
    }
  }

  .andes-modal__content {
    padding: 40px 52px 39px;
  }

  .#{$search-prefix}-icon {
    &--supermarket-logo {
      width: 194px;
      height: 30px;
      position: relative;
      top: 8px;
    }
  }
}

.andes-modal--small {
  .#{$search-prefix}-value-propositions-details-modal {
    &.andes-modal {
      max-width: 562px;
      max-height: 367px;
      border-radius: 8px;
    }

    &--VOLUME_DISCOUNT_V1 {
      &.andes-modal {
        max-height: 420px;
      }
    }
  }
}
