@import '../../styles/variables';

$padding-highlight-side: $andes-spacing-4;
$padding-highlight: 0 $padding-highlight-side;
$font-size-highlight: $font-size-12;
$font-weight-highlight: $font-weight-semibold;

.#{$ui-pills-builder}-highlight {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  padding: $padding-highlight;
  border-radius: 2px;
  border-collapse: collapse;
  font-size: $font-size-highlight;
  font-weight: $font-weight-highlight;
  line-height: 19px;
  color: $andes-white;
  background: $andes-green-500;

  .price-tag.#{$ui-pills-builder}-price__part {
    line-height: 1;
    vertical-align: middle;
    transform: translateY(-1px);
  }

  & > span {
    &:not(:last-child) {
      &::after {
        content: ' ';
      }
    }
  }

  svg {
    max-height: 14px;
    margin: 0;
    vertical-align: middle;
    transform: translateY(-1px);

    &:not(:last-child) {
      margin-right: 0.25em;
    }
  }

  &-wrapper {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: start;
    flex-wrap: wrap;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    line-height: $font-size-highlight;
  }

  &-content {
    display: inline;
  }
}
