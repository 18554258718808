.#{$search-prefix}-winner-alternatives {
  &__item {
    margin-bottom: 6px;
  }

  &--separator-top,
  &--separator-top--grid {
    border-top: 1px solid #00000012;
  }

  &--chevron {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0000001a;
    border-radius: 4px;
    padding: 8px;
  }

  &--sandwich {
    $border: 1px solid #00000012;
    border-top: $border;
    border-bottom: $border;
    padding: 8px 16px 8px 0;
  }

  &--border-grey {
    border: 1px solid #00000012;
    border-radius: 4px;
    padding: 12px 8px;
  }

  &--border-blue {
    border: 2px solid #3483fa;
    border-radius: 4px;
    padding: 12px 8px;
  }
}
