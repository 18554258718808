$pagination-arrow-size: 7px;
$andes-pagination: 'andes-pagination';
$andes-hover-color: #1259c3;
.#{$andes-pagination} {
  &__arrow {
    @include square($pagination-arrow-size);
    display: inline-block;
    border-style: solid;
    border-width: 1px 1px 0 0;
    border-color: $andes-gray-550;
    margin-top: 0;
  }

  &__button--back {
    .#{$andes-pagination}__arrow {
      transform: rotate(225deg);
    }

    .#{$andes-pagination}__arrow-title {
      margin-left: 8px;
    }
  }

  &__button--next {
    .#{$andes-pagination}__arrow {
      transform: rotate(45deg);
    }
    .#{$andes-pagination}__arrow-title {
      margin-right: 4px;
    }
  }

  &__button--disabled {
    display: none;
  }

  &__button--current .#{$andes-pagination}__link {
    cursor: auto;
  }
}
