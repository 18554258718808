$primary-color: #e5e5e5;
$secondary-color: #d3d3d3;

.#{$search-prefix}-fullsnackbar-skeleton {
  border-radius: 4px;
  background-color: $primary-color;
  overflow: hidden;
  position: relative;

  &::after {
    animation: wave 2s linear 0.5s infinite;
    background: linear-gradient(90deg, $primary-color, $secondary-color, $primary-color);
    content: '';
    position: absolute;
    transform: translateX(-100%);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  &__circle {
    height: 32px;
    width: 32px;
    margin-right: $andes-spacing-12;
  }

  &__header {
    justify-content: center;
    flex: 1;
  }

  &__icon-line-container {
    display: flex;
  }

  &__line-container {
    width: calc(100% - 44px);
  }

  &__line {
    &--large {
      height: 18px;
    }

    &--small {
      height: 4px;
      margin: $andes-spacing-8 0;
    }

    &--extra-large {
      height: 32px;
      margin-top: $andes-spacing-12;
    }
  }

  @keyframes wave {
    0% {
      transform: translateX(-100%);
    }

    50% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}
