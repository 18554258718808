@import './label';
@import '../../../../components-v2/icons/styles/desktop';

.ui-search__discount--shops.ui-pb-label-builder {
  border-radius: 16px;
  display: flex;
  font-weight: $font-weight-semibold;
  padding: $andes-spacing-4 $andes-spacing-8;

  .ui-pb-label {
    font-size: $font-size-14;
  }
}
