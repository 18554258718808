@import '../../../styles/variables';

.#{$search-prefix}-bookmark {
  position: absolute;
  z-index: $z-index-m;
  top: 0;
  right: 0;

  &__btn {
    background-color: transparent;
    border: none;
    display: inline-flex;
    padding: 16px;
    height: 48px;
    width: 49px;
  }

  &__icon-bookmark,
  &__icon-bookmark-fill {
    fill: none;
    stroke: $andes-blue-500;
    height: 15px;
    overflow: visible;
    position: absolute;
    top: 16px;

    g {
      stroke-width: 1.5;
    }
  }

  &__icon-bookmark-fill {
    fill: $andes-blue-500;
    transition: opacity 0.25s ease-in-out, transform 0.35s cubic-bezier(0, 0, 0.3, 1.5);
    opacity: 0;
    transform: scale(0);

    &--active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__cpg {
    left: 100px;
    right: unset;
    top: 16px;

    .#{$search-prefix}-bookmark__btn {
      height: 32px;
      width: 32px;
      background-color: $search-white-900;
      border-radius: 50%;
      padding: 8px;
    }

    .#{$search-prefix}-bookmark__icon-bookmark,
    .#{$search-prefix}-bookmark__icon-bookmark-fill {
      top: 8px;
    }
  }
}
