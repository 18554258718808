@import '../../../styles/variables';

.#{$search-prefix}-banner {
  $self: &;

  align-items: center;
  display: flex;
  margin-bottom: $andes-spacing-16;
  padding: $andes-spacing-24;
  width: 100%;

  &__wrapper {
    width: 100%;
  }

  &__logo {
    align-items: center;
    background-color: $andes-white;
    border-radius: 4px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 90px;
    margin-right: $search-spacing-36;
    width: 150px;

    #{$self}__image {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__first-block {
    line-height: 1.5;
    margin-right: $search-spacing-36;
    width: 100%;
  }

  &__second-block {
    line-height: 1.5;
    color: $search-gray-1000;
    width: 100%;
  }

  &__title {
    font-size: $font-size-18;
    font-weight: $font-weight-semibold;
    color: $search-gray-1000;
  }

  &__subtitle {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    color: $search-gray-666;
  }

  &__description {
    font-size: $font-size-14;
    font-weight: normal;
  }

  &-reputation__title {
    color: $search-gray-1000;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 1;
    margin-bottom: $search-spacing-14;
  }

  &-reputation__text {
    font-size: $font-size-14;
    font-weight: normal;
    line-height: 1;
  }

  .#{$search-prefix}-reputation-scale {
    margin-bottom: 9px;
    max-width: 200px;
  }

  &--SHOWCASE {
    #{$self}__logo {
      height: 61px;
      width: 99px;
    }

    #{$self}__description {
      color: $search-gray-1000;
      font-size: $font-size-12;
    }

    #{$self}__second-block {
      width: 50%;
    }

    #{$self}-reputation__title {
      margin-bottom: 9px;
    }

    .#{$search-prefix}-reputation-scale__level:last-child,
    .#{$search-prefix}-reputation-scale__level:first-child {
      border-radius: 0;
    }

    #{$self}-reputation__badge-wrapper {
      display: flex;
    }

    #{$self}-reputation__badge-text {
      display: grid;
      margin-left: 10px;
      line-height: 1.1;
    }

    #{$self}-reputation__badge-title {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
    }

    #{$self}-reputation__badge-subtitle {
      font-size: $font-size-12;
      font-weight: $font-weight-light;
    }
  }
}
