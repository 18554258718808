@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-item__group--pds {
  flex-direction: column;

  .ui-search-price__second-line {
    margin-top: 0 !important;

    .#{$search-prefix}-price__part {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: $line-height-m;
    }
  }

  .#{$search-prefix}-item__pds-options {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-m;
  }
}
