@import '../../../styles/variables';

.#{$search-prefix}-header--deal {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: block;
  margin: 0;
  min-height: 242px;
  width: 100%;
}
