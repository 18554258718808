@import '../../../styles/variables';
@import '../../../styles/mixins';

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-main--without-header {
  .#{$search-prefix}-sidebar {
    margin-top: 4px;
  }
}

.#{$search-prefix}-sidebar {
  flex-wrap: wrap;
  flex: 1 1;
  max-width: 294px;
  min-width: 210px;
  padding-right: 14px;
  width: 100%;

  & .#{$search-prefix}-sidebar__result-container {
    display: flex;
    height: 32px;
  }

  &--h1-multi-neighborhood {
    .#{$search-prefix}-breadcrumb__title {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 90px;
    }
  }

  .#{$search-prefix}-styled-label.screen-reader-only {
    @include sr-only;

    &:focus {
      position: static;
      width: auto;
      height: auto;
      padding-top: -10px;
    }
  }
}

// Variants
.#{$search-prefix}-main {
  &--official-store {
    .#{$search-prefix}-sidebar {
      margin-top: $search-spacing-52;
    }
  }

  &--deals {
    .#{$search-prefix}-sidebar {
      margin-top: 23px;
    }
  }
}
