.#{$search-prefix}-composed-label-alternatives {
  &__container {
    display: flex;
    align-items: center;
    width: fit-content;
    flex-wrap: wrap;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__items {
    display: inline-block;
  }

  &--value,
  &--normal-text {
    margin: 0;
    font-family: inherit;
    white-space: pre-wrap;
    vertical-align: middle;
    display: inline;
  }

  &__price {
    display: inline-block;
    vertical-align: middle;
  }
}
