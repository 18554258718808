$pill-colors: (
  'yellow': #fff1cb,
  'brown': #a47b2e,
  'blue': $andes-blue-500,
  'dark-blue': $andes-blue-200,
);

//sass-lint:disable no-color-keywords

// Prefixes
$ui-pills-builder-prefix: 'ui-pb';

// font
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-20: 20px;
$font-size-22: 22px;

//spacing
$margin-4: 4px;
$margin-5: 5px;
$margin-8: 8px;
$margin-12: 12px;
$margin-14: 14px;
$margin-16: 16px;

$font-weight-bold: bold;

$ui-pills-builder: $ui-pills-builder-prefix;

$ui-pills-builder-colors: (
  andes-blue-ml-500: #3483fa,
  andes-gray-250-solid: #bfbfbf,
  andes-orange-500: #f73,
  andes-secondory-k-100: #fff1cb,
  andes-secondory-k-800: #a47b2e,
  andes-bg-color-white: #fff,
  andes-bg-color-black: #000,
  andes-blue-ml-200: rgba(65, 137, 230, 0.2),
  andes-text-color-primary: $andes-gray-900,
  andes-text-color-secondary: $andes-gray-550,
  andes-text-color-disabled: rgba(0, 0, 0, 0.25),
  andes-text-color-inverted: rgba(255, 255, 255, 255),
  andes-green-500: #00a650,
);
