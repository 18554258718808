@import '../../../../styles/variables';
@import '../../../../styles/mixins';

$price-component-prefix: #{$search-prefix}-price;

.#{$price-component-prefix} {
  &__part {
    display: flex;
    width: fit-content;
  }
}
