@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import 'eshops-components-library/src/components/BottomSheet/index';
@import './wrapper-eshop';

.wrapper-sticky > .ui-ms-profile__header {
  .ui-ms-profile__wrap-description {
    font-weight: 400;

    .ui-ms-profile__title svg {
      vertical-align: -2px;
    }
  }
}
